import React, {Component} from "react";

class RootLevelErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state  = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    process.env.REACT_APP_STAGE !== "prod" &&
    console.error(error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}

export default RootLevelErrorBoundary;