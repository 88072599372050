import BaseService from "./BaseService";
import axios from "axios";
import Config from "../config";

class OffersService extends BaseService {
  constructor() {
    super();
  }

  async getSourceForAdditionalOffers(queryString) {
    try {
      const options = this.axiosOptions()
        .asPOST()
        .withJSONHeaders()
        .withURL(Config.SOURCE_ONLY_URL)
        .withData({queryString, source: "Accuquote"})
        .build();

      const response = await axios(options);

      return {
        success: true,
        data: response.data
      };
    } catch(error) {
      return {
        success: false
      };
    }
  }

  async getRejectOffers(queryString, deviceType, coverageAmount, originalLeadId, debugMode) {
    try {
      let {trackingToken} = window;

      const options = this.axiosOptions()
        .asPOST()
        .withJSONHeaders()
        .withURL(Config.REJECT_OFFERS_URL)
        .withData({
          queryString,
          source: "Accuquote",
          localHour: ((new Date()).getHours()),
          userAgent: window.navigator.userAgent,
          token: trackingToken,
          leadId: originalLeadId,
          deviceType,
          coverageAmount
        })
        .build();

      const response = await axios(options);

      let responseData = response.data;
      return {
        success: true,
        data: responseData
      };
    } catch(error) {
      return {
        success: false
      };
    }
  }
}

export default new OffersService();