import React from "react";

const ConfirmationPageQuoteSection = props => {
  return (
    <section id="quote">
      <article>
        <aside>
          <p><strong>Thank you!</strong> An agent will contact you shortly.</p>
          <p className="comparequotes">Compare quotes from additional insurance providers</p>
        </aside>
      </article>
    </section>
  );
}

export default ConfirmationPageQuoteSection;