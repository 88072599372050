import BaseService from "./BaseService";
import axios from "axios";
import Config from "../config";

class LeadService extends BaseService {
  constructor() {
    super();
  }

  async postLeadData(leadDataPayload, deviceType, isDebug) {    
    try {
      let {trackingToken} = window;

      const options = this.axiosOptions()
       .asPOST()
       .withJSONHeaders()
       .withURL(Config.LEAD_POST_URL)
       .withData(
          {...leadDataPayload,
            source: "Accuquote",
            productType: window.location.href.includes("final-expense") ? "FinalExpense" : "Term",
            localHour: ((new Date()).getHours()),
            token: trackingToken,
            deviceType
          }
        )
       .build();

      const response = isDebug ? {data: Config.debugConfirmationResponse} : await axios(options);

      return {
        success: true,
        data: response.data
      };
    } catch(error) {
      let errors = error.response.data.errors
      return {
        success: false,
        errors
      };
    }
  }

  async selectLead(leadId) {
    if (!leadId) {return;}
    try {
      const options = this.axiosOptions()
      .asPOST()
      .withJSONHeaders()
      .withURL(Config.LEAD_SELECTION_URL)
      .withData({offerId: leadId, source: "Accuquote"})
      .build();

      await axios(options);
      return {
        success: true
      };
    } catch(error) {
      return {
        success: false,
        error
      };
    }
  }
}

export default new LeadService();