import React, {Component} from "react";
import LeadIdScriptSetup from "../analytics/LeadIdScriptSetup";

class LeadIdComp extends Component {
  constructor(props) {
    super(props);
    this.leadInit = false;
    this.leadInitSnap = false;
  }

  componentDidMount() {
    let showNewScript = process.env.REACT_APP_STAGE !== "prod" && window.location.href.includes("new_script=true");
    if (showNewScript) {
      var pollingInterval = setInterval(()=>{        
        if (window.LeadiD) {
          window.LeadiD.snap();
          clearInterval(pollingInterval);
        }
      }, 100);  
    }    
  }

  componentDidUpdate() {
    if (this.props.formClicked && !this.leadInit) {
      this.leadInit = true;
      this.leadIdScriptSetup = new LeadIdScriptSetup();
      this.leadIdScriptSetup.attachNormalScript();      
    }
  }
  render() {
    return (
      <div/>
    )
  }
}

export default LeadIdComp;