import React from "react";

const ConfirmationPageQuoteSection = props => {
  let {maxWidth} = props;
  return (
    <section className="modalmsg">
      <article id="abandon-modal-article">
        <h1>We find you the lowest rates on life insurance.</h1>
        <h2>Compare quotes from additional insurance providers</h2>
      </article> 
    </section>
  );
}

export default ConfirmationPageQuoteSection;