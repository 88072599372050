import React from "react";

const ConfirmationPageHeader = props => {
  let {maxWidth} = props;
  return (
    <section id="logo">
      <article>
        <img
          src={require("../../../Assets/images/logo-accuquote.svg")}
        />
      </article>
    </section>
  );
}

export default ConfirmationPageHeader;