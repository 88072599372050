import React from "react";
import ReactHtmlParser from "react-html-parser";

const ConfirmationPageContentSection = props => {
  return (
    <section className="abandon-body">
    <div>
        <div id="SHlistings" className="shChrome shSafari">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
            id="listingResults"
          >
            {/*begin row*/}
            {
              props.offers &&
              props.offers.map((i, k) => {
                return (
                  <div
                    onClick={() => {
                      props.selectLead(i.id);
                      window.open(i.url);
                    }}
                    style={{
                      width: "100%",
                      minHeight: 50,                      
                      boxShadow: "1px 2px 4px rgba(0, 0, 0, .5)",
                      marginTop: 5,
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: 5
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          alignItems: "center",
                          height: 80
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 5,
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: "lightgrey",
                            width: "100%"
                          }}
                        >
                          <img
                            style={{objectFit: "contain", maxHeight: 80, maxWidth: 120}}
                            src={i.imageUrl}
                          />
                        </div>
                      </div>
                      <div style={{display: "flex", flex: 1, alignItems: "center", justifyContent: "center"}}>
                        <div
                          style={{
                            background: "none rgb(255, 103, 27)",
                            border: "none",
                            color: "white",
                            padding: "5px 0px",
                            borderRadius: 5,
                            width: "70%",
                            textShadow: "none",
                            fontSize: 14,
                            textAlign: "center",
                            fontWeight: "bold"                                    
                          }}
                        >
                          Mobile <br/> Quote
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        textAlign: "center",
                        padding: 10
                      }}
                    >
                      <span
                        style={{
                          color: "rgb(33, 101, 162)",
                          fontSize: "1em"
                        }}
                      >
                        {i.headline}
                      </span>
                      <div style={{display: "none"}}>
                        {
                          ReactHtmlParser(i.additionalHtml)
                        }
                      </div>
                    </div>
                  </div>
                );
              })
            }
            {/*end row*/}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ConfirmationPageContentSection;