import {EventStatuses} from "../system";

export const EVENT_STATUS_CHANGE = "EVENT_STATUS_CHANGE";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const INIT_SHOPIFY_SUCCESS = "INIT_SHOPIFY_SUCCESS";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const CUSTOMER_LOGIN_SUCCESS = "CUSTOMER_LOGIN_SUCCESS";
export const CUSTOMER_LOGOUT_SUCCESS = "CUSTOMER_LOGOUT_SUCCESS";
export const POST_LEAD_DATA_SUCCESS = "POST_LEAD_DATA_SUCCESS";
export const FAILED_TO_POST_LEAD_DATA = "FAILED_TO_POST_LEAD_DATA";
export const GET_SOURCE_FOR_ADDITIONAL_OFFERS_SUCCESS = "GET_SOURCE_FOR_ADDITIONAL_OFFERS_SUCCESS";
export const GET_ABANDONMENT_OFFER_DATA_SUCCESS = "GET_ABANDONMENT_OFFER_DATA_SUCCESS";
export const SET_CLICK_ID = "SET_CLICK_ID";
export const FIELD_FOCUSED = "FIELD_FOCUSED";

export const startEvent = eventName => {
  return {
    type: EVENT_STATUS_CHANGE,
    eventStatus: EventStatuses.PENDING,
    eventName
  };
};

export const completeEvent = eventName => {
  return {
    type: EVENT_STATUS_CHANGE,
    eventStatus: EventStatuses.COMPLETED,
    eventName
  };
};

export const failEvent = eventName => {
  return {
    type: EVENT_STATUS_CHANGE,
    eventStatus: EventStatuses.FAILED,
    eventName
  };
};

export const acknowledgeEvent = eventName => {
  return {
    type: EVENT_STATUS_CHANGE,
    eventStatus: EventStatuses.ACKNOWLEDGED,
    eventName
  };
};

export const postLeadDataSuccessful = (pixels, source, offers, token) => {
  return {
    type: POST_LEAD_DATA_SUCCESS,
    pixels,
    source,
    offers,
    leadIdToken: token
  };
};

export const failedToPostLeadData = errors => {
  return {
    type: FAILED_TO_POST_LEAD_DATA,
    errors
  };
};

export const getSourceForAdditionalOffersSuccessful = (alternateOffersBrokerSource, landingPagePixels) => {
  return {
    type: GET_SOURCE_FOR_ADDITIONAL_OFFERS_SUCCESS,
    alternateOffersBrokerSource,
    landingPagePixels
  };
};

export const setClickId = clickId => {
  return {
    type: SET_CLICK_ID,
    clickId
  };
};

export const fieldFocused = () => {
  return {
    type: FIELD_FOCUSED
  };
};