const UAStringsQA = {
  default: "UA-142472253-1"
};

const UAStringsProd = {
  default: "UA-156660553-1"
};

const AWStringsQA = {
  default: "AW-TEST",
  fidelity: "AW-TEST2"
};

const AWStringsProd = {
  default: "AW-671603811",
  fidelity: "AW-1010597062"
};

const AWPixelsQA = {
  default: "TESTPIXEL",
  fidelity: "TESTPIXEL2"
};

const AWPixelsProd = {  
  default: "awtJCOnY1b8BEOO4n8AC",
  fidelity: "Fyw6COKk3gMQxvnx4QM"
};

const BingAnalyticsStrings = {
  default: "17338330",
  finalExpense: "17338330"
};

const debugPayload = {
  firstName: "Tester",
  lastName: "Tester",
  gender: "Male",
  zip: "75077",
  dobDay: "25",
  dobMonth: "07",
  dobYear: "1993",
  phone: "5128675309",
  email: `${new Date().valueOf()}@mail.com`,
  queryString: "",
  leadIdToken: ""
};

const debugAbandonResponse = {
    "randomDigit": 0,
    "offers": [
        {
            "description": "<ul><li>No medical exam</li><li>Affordable Coverage Designed to Fit Your Budget</li><li>You Could Get TruStage Term Life in Minutes</li></ul>",
            "headline": "Get a Free &apos; Term Life Insurance Quote",
            "url": "//insurance-test.mediaalpha.com/click.html?cu=https%3A%2F%2Fservedby.flashtalking.com%2Fclick%2F8%2F109059%3B3805217%3B369310%3B252%3B0%2F%3Fft_keyword%3DTRUS_MVF_TERMxxx_COUSA_MA_DB_WTRF_1300DegrPD%26ft_section%3DIMLFI_00XXXXCPC_XXXX_XXXX_1300DegreesPD_XXXXX_Y0009_DWXEN_MEALOA%26ft_custom%3D__%26url%3Dhttps%3A%2F%2Fwww.trustage.com%2Flife%2Fquote%3Fpr%3D0%26utm_source%3Dkepler_np_tl%26utm_medium%3Dcmg_np_tl%26utm_content%3DTRUS_MVF_TERMxxx_COUSA_MA_DB_WTRF_1300DegrPD_IMLFI_00XXXXCPC_XXXX_XXXX_1300DegreesPD_XXXXX_Y0009_DWXEN_MEALOA_TMQT_01_EN_JP_CRLinks%26utm_campaign%3D1300%2520Degrees_G3V37FW0H9%2520%257C%2520M2G84DU6N4%2520%257C%2520Life%2520-%2520Partial%2520Data_AALHMJUGAAAAAAH735P7GGNQILE5M_Computer%26utm_kxconf%3Dtp4ukbsy3&ad_pos={position};Su1yxX_vXlAwZi9Eon6pA9viFjPun87bvc4tnzOr7hi1S7Do_a1EmnJLHPTdVB9eBpxj9IjQCaYl-LUwoqRz7c9oeFJN0wuzBzShOvYtrSqE7vHIL53ZOea4s8QXN9KEtBb8Tat4b2KCsXVbof0x3n_IHiSex8V5QM-cRoaHVS0mKfeE74XXCIe_teKz0T91W18sH7Kr83N1tSDn9gytUu0vFhr5wpmZtPs3L7SwynMyfqwsIP5HToWU6f7ooyAkYRYI1Yjh01jCOjj-W08Sm4AWph49-bWmHwAYSlrUy7LX8klvWA6Yf-hTHyEn03aG8jI5lAkn2Kx2zb4G37FWJ38AugshBQsGi3dBV3ObHx0V0ENjQz-rIuokWazZrpQxmn-w2p2xA70t_vkIMLeL1_AdJcKdkFoaCOdaAck6CX-nFMsVsjuXQ1bapgNTNqQSYLLKqHagOLdMddB-9NoY4l3C8yO1kw",
            "imageUrl": "//d29u10q7qlh006.cloudfront.net/i/i/788/Plc1zqE1XIR0p_Mc__71iFuusY4.png"
        },
        {
            "description": "<ul><li>Coverage for Adults and Children</li><li>No Medical Exam - Simple Application</li><li>Free Info. No Phone Required. No Waiting</li><li>Buy Direct. Monthly Rates as low as $3.49 for Adults/$2.17 for Children</li></ul>",
            "headline": "$1* Buys $50,000 Globe Life Insurance",
            "url": "//insurance-test.mediaalpha.com/click.html?cu=https%3A%2F%2Fu9ka.globelifeinsurance.com%2F%3Fstate%3DTX%26gmad_id%3Dmda-850400%26utm_source%3DMediaAlpha%26utm_medium%3DInsuranceSearch%26utm_campaign%3D1300%2520Degrees%26utm_term%3DTX%26exlmt%3Dtr%26subid%3D1300%2520Degrees%26subsubid%3DG3V37FW0H9%2520%257C%2520M2G84DU6N4%2520%257C%2520Life%2520-%2520Partial%2520Data%26channel%3DG3V37FW0H9%2520%257C%2520M2G84DU6N4%2520%257C%2520Life%2520-%2520Partial%2520Data%26channel_id%3D3652%26zipcode%3D76208%26lead_id%3D2250667542&ad_pos={position};Su1yxX_vXlAwZi9Eon6pA9viFjPun87bvc4tnzOr7hi1S7Do_a1EmnJLHPTdVB9eBpxj9IjQCaYl-LUwoqRz7c9oeFJN0wuzBzShOvYtrSqE7vHIL53ZOea4s8QXN9KEtBb8Tat4b2KCsXVbof0x3n_IHiSex8V5QM-cRoaHVS0mKfeE74XXCIe_teKz0T91KbyIwCi5wAYSVMJ5jsZuj2Yyp1p0trCPqEAcOENBqFid93Hhd_YWYhLnDj3SPBk8q1JkVDjKTSoAZo9SIoCBCSuXEx5iZkfIWGPpOd5PkBrLG5wBN7iuHO86f-PGfPFAb2IYiQbptufqKKMd1_KvvJ8g8VVfmROOKGIkG05NKLu7zWjY0Wnt9F0vhIjB4znvMvYGImMeRVM7rNdrY1wb1xEYUJ7aNOpilODccTbdAmCvoc4AtApjdSzjIdjVcA5EQ3mQxfPa",
            "imageUrl": "//d29u10q7qlh006.cloudfront.net/i/i/97/8FYIHdPOOjLmBvu6X9H1Z8kRB3Q.png"
        },
        {
            "description": "<ul><li>Get an instant rate quote and apply today.</li><li>Ages 18 to 69.</li><li>Personalized service. Privacy guaranteed.</li><li>Skip the middleman. Buy directly from the company.</li></ul>",
            "headline": "Great Life Insurance is only a click away when you buy directly from American National Insurance Company",
            "url": "//insurance-test.mediaalpha.com/click.html?cu=https%3A%2F%2Faccess.anico.com%2Fgslife%2Fanicodirectpos%2FinitProc.dhtml%3Fproc%3DfastQuote%26ogn%3Dlifeinsuranceorg%26subid%3D542_1300%2520Degrees&ad_pos={position};Su1yxX_vXlAwZi9Eon6pA9viFjPun87bvc4tnzOr7hi1S7Do_a1EmnJLHPTdVB9eBpxj9IjQCaYl-LUwoqRz7c9oeFJN0wuzBzShOvYtrSqE7vHIL53ZOea4s8QXN9KEtBb8Tat4b2KCsXVbof0x3n_IHiSex8V5QM-cRoaHVS0mKfeE74XXCIe_teKz0T91xguPOun_UuKJ5byczGdgZsrI_aoIfaAonV57L2a9Ayh_OV9xaYcLjd5t4BwVgQIfXkINXcFl_aIUbZyMZmFsEO8vZo6dkM0dPQGtQK9_J6kMp6jTNgh0e6XAHWFQ1RHPCjv1XwIKRSz1UqLjWRBb8hsEIuiW5rmXQR6pte5cnIMxzueQmBssIu3tdAGgRPurQl_3n0BET0fStzOa96e5bEE6jbgdMyNKBPDxIx2yxsNbbi081TFQc9tRo8x0KQ00-q0do1In",
            "imageUrl": "//d29u10q7qlh006.cloudfront.net/i/i/319/GyGpn8nwkhijELCjCzV3-gQECz8.png"
        },
        {
            "description": "<ul><li>Save up to 70% on your term life policy &quot;</li><li>Get a free, no-obligation term life insurance quote</li><li>Over 88 million customers worldwide rely on AIG</li><li>It takes 2 minutes to request your term life quote</li></ul>",
            "headline": "Get $250,000 of Term Life Coverage for as Low as $14/Month &quot;",
            "url": "//insurance-test.mediaalpha.com/click.html?cu=https%3A%2F%2Fwww.aigdirect.com%2Frequest-a-quote%3FDNIS%3D9416%26CMPID%3DAALHMJUGAAAAAAFPWNH3OYFQILR3E&ad_pos={position};Su1yxX_vXlAwZi9Eon6pA9viFjPun87bvc4tnzOr7hi1S7Do_a1EmnJLHPTdVB9eBpxj9IjQCaYl-LUwoqRz7c9oeFJN0wuzBzShOvYtrSqE7vHIL53ZOea4s8QXN9KEtBb8Tat4b2KCsXVbof0x3n_IHiSex8V5QM-cRoaHVS0mKfeE74XXCIe_teKz0T91xguPOun_UuKJ5byczGdgZtuAbb6nO4IvEhLBrvEcjcpGp3ikzU900k40A7fa37iYG0FKllZMyGutrxZXJJ47q3pRsb3quhz7nSiA_tWEquWk28ouDUvNfpuTlaDVgG7lCqR_5751PNYuX8kA8R82cFCB5epM8ffEsAbQ1kfO5XmQzSOioW-2e-S3lfsTbvwyy9y5u6hqDD6MT55XBRT--AvQFzp2Jihn36wT52oc4f_NMXNvHIQeiYiOa-OKvXog7t3P7TR4",
            "imageUrl": "//d29u10q7qlh006.cloudfront.net/i/i/282/0iCW3ReOISAjCayJCZd6eClQ2u8.png"
        }
    ]
};

const debugConfirmationResponse = {
    "errors": [],
    "pixels": [
        "https://qualqt.com/p.ashx?o=1&e=3&t=445b04f6-d33a-45c5-8c54-2e7161997c58&r=",
        "https://qualqt.com/p.ashx?o=5&e=3&t=445b04f6-d33a-45c5-8c54-2e7161997c58&r="
    ],
    "source": null,
    "offers": [
        {
            "description": "<ul><li>Variety of Life Insurance Plans</li><li>Provide for your Loved Ones</li><li>Whole Life Insurance for as Low as $0.86 a Day!</li><li>Universal Life Insurance with Cash Value</li></ul><img src=\"https://www.nextinsure.com/ListingDisplay/Handlers/pv.ashx?SI=076eb397-efa9-4a84-a123-68c79da3058f&SDT=636979313700674951&CAT=5\" style=\"display:none;\">",
            "headline": "AAA® Life Insurance in Texas (Official Site)",
            "url": "https://www.nextinsure.com/ListingDisplay/Click/?C=NjMxNzExNA%3d%3d&I=MzBhNGFlYTctMTcxYy00YzQxLTljY2MtZDlhYWIxZWRkMWYw&U=aHR0cHM6Ly9hZC5kb3VibGVjbGljay5uZXQvZGRtL3RyYWNrY2xrL04xMDEyMzIwLjExMjgxN1FVSU5TVFJFRVQvQjIyMjQ1NjMwLjIzOTMyNTg4NTtkY190cmtfYWlkPTQzNjIwMTA2NDtkY190cmtfY2lkPTExMTY5NTk1NDtkY19sYXQ9O2RjX3JkaWQ9O3RhZ19mb3JfY2hpbGRfZGlyZWN0ZWRfdHJlYXRtZW50PTt0ZnVhPQ%3d%3d&S=NjYyODU1&SC=NjI3Njk1NA%3d%3d&W=NQ%3d%3d&SI=MDc2ZWIzOTctZWZhOS00YTg0LWExMjMtNjhjNzlkYTMwNThm&SDT=NjM2OTc5MzEzNzAwNjc0OTUx&Z=NTFmNTdjOWVmMTM3MmY1OGE3OGViODRiYTVjYmZkMWY%3d&B=NTY5NTI5&L=NTU4MTczNjM%3d&WS=U0gtTERFLVdFQjM%3d&IV=dll6QlZDdkJxNU9ycGhpZE9OZU1yS3JVdDd1MllpY2Y4dllrbDVHb3RSQXYxa0s4b0RzWHB3PT0%3d&A=0&V=TmRLUEtxV2pESms9&QMPC=L3hhcGktY2FwdHVyZT9CTFVJRD0yMDE5MDcwNTExNDkxMjAyMDY4MjE5OjAxNDgwODQxMDowMDMmc2wucG9zPTMmc2wuY2s9NDI3Nzc2MTAmc2wuZGs9MTk1Njk5MTAmc2wuY209MTY4MzEwJnNsLmRoPTEzMTIxMzEwJnNsLnNjPUxJRkUmc2wuY2F0PTUxNTAmc2wud2lrPTIxMjg2ODEwJnNsLmFmZj04NDYxNzM0NCZzbC5xYj0wJnNsLmVuYz0yMDQ2NjQ4MTAmc2wuaXQ9MTA5NTM2MTAmc2wuY3J0PTI1ODM4OTMxMCZzbC5zZWFyY2hJZD0wNTMwZmI4ZS04NDY3LTRhYWEtOGRhZS1iOTU5M2M5NDUwOTU6OjEmc2wubWF0Y2hlZGVudGl0eWdycGtleXM9MTMwMTAsNjM0NjAmdGFnPTIwNDY2NDgxMCZDQ0lEPTIwMzIwODA4MjA0NjY0ODEwJnN0YXRlPVRYJmNvdW50cnk9VVMmemlwPTc1MDc3JnNsLm1wPVNrMCUyQnlBbTE2eU5BOUZoamh2bjJTQSUzRCUzRCZzbC5za2lwcmVkaXJlY3Q9MSY%3d&T=MQ%3d%3d",
            "imageUrl": "//qmpcdn.nextinsure.com/content/one/9333/AAATexas_Orbit_Logo_QMPFIT.jpg"
        },
        {
            "description": "<ul><li>Save up to 70% on your term life policy</li><li>Get a free, no-obligation term life insurance quote</li><li>Over 88 million customers worldwide rely on AIG</li><li>It takes 2 minutes to request your term life quote</li></ul><img src=\"https://www.nextinsure.com/ListingDisplay/Handlers/pv.ashx?SI=076eb397-efa9-4a84-a123-68c79da3058f&SDT=636979313700674951&CAT=5\" style=\"display:none;\">",
            "headline": "Get $250,000 of Term Life Coverage for as Low as $14/Month",
            "url": "https://www.nextinsure.com/ListingDisplay/Click/?C=NzY%3d&I=YjJjMDY2ZDUtZThlZS00OWI5LWE3OWYtZjVlNGI3YjQ5YzIw&U=aHR0cDovL3d3dy5haWdkaXJlY3QuY29tL3JlcXVlc3QtYS1xdW90ZT9kbmlzPTk3NjUmb3ZjaG49T1RIRVImb3ZjcG49VGV4YXMmb3Zjcm49VGV4YXMmb3Z0YWM9QUZGJlRyYW5zYWN0aW9uSUQ9JGNsaWNra2V5JF8yMDQ2NjQ4MTA%3d&S=NjYyODU1&SC=NjI3Njk1NA%3d%3d&W=NQ%3d%3d&SI=MDc2ZWIzOTctZWZhOS00YTg0LWExMjMtNjhjNzlkYTMwNThm&SDT=NjM2OTc5MzEzNzAwNjc0OTUx&Z=OTJlM2IzNzAxNDUyODFkYzBlY2RlOGI4YjM2MGY5MzY%3d&B=MjUx&L=MjgyOTg4MQ%3d%3d&WS=U0gtTERFLVdFQjM%3d&IV=VklORTExQlViR1NVVDcxTlIvNXhwbVVSeDllVnZOanRSUEl3TmRqWElURTJaVC80clkyUjBnPT0%3d&A=0&V=SG5udkdIU3pZVWc9&QMPC=L3hhcGktY2FwdHVyZT9CTFVJRD0yMDE5MDcwNTExNDkxMjAyMDU3OTcyOjAwMTUyMzkxMDowMDImc2wucG9zPTImc2wuY2s9NzUxMTE5MTAmc2wuZGs9MTcxMzc5MTAmc2wuY209MTY4MzEwJnNsLmRoPTEyMDQ3MDEwJnNsLnNjPUxJRkUmc2wuY2F0PTUxNTAmc2wud2lrPTIxMjg2ODEwJnNsLmFmZj04NDYxNzM0NCZzbC5xYj0wJnNsLmVuYz0yMDQ2NjQ4MTAmc2wuaXQ9MTA5NTM2MTAmc2wuY3J0PTIwNjU5NjkxMCZzbC5zZWFyY2hJZD0wNTMwZmI4ZS04NDY3LTRhYWEtOGRhZS1iOTU5M2M5NDUwOTU6OjEmc2wubWF0Y2hlZGVudGl0eWdycGtleXM9NDE2MCwyMTAyMTAmdGFnPTIwNDY2NDgxMCZDQ0lEPTIwMzIwODA4MjA0NjY0ODEwJnN0YXRlPVRYJmNvdW50cnk9VVMmemlwPTc1MDc3JnNsLm1wPTdSaGFWZERJYW9ObTFQQTFjTktMTnclM0QlM0Qmc2wuc2tpcHJlZGlyZWN0PTEm&T=MQ%3d%3d",
            "imageUrl": "//qmpcdn.nextinsure.com/content/one/3870/AIG Direct Sure Hits Logo 8.13.15..jpg"
        },
        {
            "description": "<ul><li>Rates as low as $16/mo for $300,000 in coverage*—healthy habits help you save even more.</li><li>Get Apple Watch for as little as $25** to help record your progress.</li><li>Earn rewards and discounts at Amazon, Starbucks, Walmart and more.</li><li>Apply online in minutes, no medical exam required.</li></ul><img src=\"https://www.nextinsure.com/ListingDisplay/Handlers/pv.ashx?SI=076eb397-efa9-4a84-a123-68c79da3058f&SDT=636979313700674951&CAT=5\" style=\"display:none;\">",
            "headline": "Life Insurance that saves you money and rewards you for healthy habits",
            "url": "https://www.nextinsure.com/ListingDisplay/Click/?C=NjM3NzYxOQ%3d%3d&I=NTE2OTc1OWEtNTQ4Zi00OWVmLTlhNjQtZWE1NzU2NjFlYTM5&U=aHR0cHM6Ly90ZXJtbGlmZS5qb2huaGFuY29ja2luc3VyYW5jZS5jb20vbHAyL3ZpdGFsaXR5LWxpZmUtcXVvdGUtY29tYmluZWQ%2fY2lkPVVTX0pIX0lOX0xBX1FVSU5fRFRDX1NQVFZfQjJDX0FEX1NOXzAwXzAwX1BSXzAwX0FRX1hfMjAxOTA1MDdfUVMxX0dFTi0yNDUtUVVJJmRjbT1jYW0lZWJ1eSEtc2l0JWVzaWQhLXBsYSVlcGlkIS1jcmUlZWNpZCEmc291cmNlPW9sYSZnb2FsPWFjcXVpc2l0aW9uJmFkdHlwZT1jdXN0b20mY2xpY2tfa2V5PSRjbGlja2tleSQ%3d&S=NjYyODU1&SC=NjI3Njk1NA%3d%3d&W=NQ%3d%3d&SI=MDc2ZWIzOTctZWZhOS00YTg0LWExMjMtNjhjNzlkYTMwNThm&SDT=NjM2OTc5MzEzNzAwNjc0OTUx&Z=NTJjYTYzY2Y0NDU4Mjk3OGUyMDlhMDk5YjVmMDEwNDM%3d&B=NjYyNjY0&L=NTY1NTgyODI%3d&WS=U0gtTERFLVdFQjM%3d&IV=RTNBblNpU0NlVlNaNW5nVzJVYk5IS1NXZ0hzYUZxS3lxVTVWWGJ5MTZ1RWplOUVXWjlZTHZBPT0%3d&A=0&V=QzZOK0RWa0hldkk9&QMPC=L3hhcGktY2FwdHVyZT9CTFVJRD0yMDE5MDcwNTExNDkxMjAyMDU3MDA4OjAyODgyMDQxMDowMDUmc2wucG9zPTUmc2wuY2s9NzY2MDI4MTAmc2wuZGs9MjcwODU5MTAmc2wuY209MTY4MzEwJnNsLmRoPTE4MzgxMDEwJnNsLnNjPUxJRkUmc2wuY2F0PTUxNTAmc2wud2lrPTIxMjg2ODEwJnNsLmFmZj04NDYxNzM0NCZzbC5xYj0wJnNsLmVuYz0yMDQ2NjQ4MTAmc2wuaXQ9MTA5NTM2MTAmc2wuY3J0PTI1NjU2NjIxMCZzbC5zZWFyY2hJZD0wNTMwZmI4ZS04NDY3LTRhYWEtOGRhZS1iOTU5M2M5NDUwOTU6OjEmc2wubWF0Y2hlZGVudGl0eWdycGtleXM9MjE1NDkwLDIxNTUwMCZ0YWc9MjA0NjY0ODEwJkNDSUQ9MjAzMjA4MDgyMDQ2NjQ4MTAmc3RhdGU9VFgmY291bnRyeT1VUyZ6aXA9NzUwNzcmc2wubXA9bnZXVnZSYXlpeWg5cjBuQmMlMkJ3VGtwVUxaQnZVZk95bU9TMUh4YWslMkJIM1UlM0Qmc2wuc2tpcHJlZGlyZWN0PTEm&T=MQ%3d%3d",
            "imageUrl": "//qmpcdn.nextinsure.com/content/one/6077/Untitled.png"
        },
        {
            "description": "<ul><li>No Medical Exam</li><li>Applying for term life insurance just might be your easiest task today</li><li>You Could Get TruStage Term Life in Minutes</li></ul><img src=\"https://www.nextinsure.com/ListingDisplay/Handlers/pv.ashx?SI=076eb397-efa9-4a84-a123-68c79da3058f&SDT=636979313700674951&CAT=5\" style=\"display:none;\">",
            "headline": "Get a Free Term Life Insurance Quote",
            "url": "https://www.nextinsure.com/ListingDisplay/Click/?C=NjM3NzYxNg%3d%3d&I=ZTNiMDdiMzktODkzZS00NDE0LTkzMjAtYjMxYTgwOGMyZjQx&U=aHR0cHM6Ly93d3cudHJ1c3RhZ2UuY29tL2xpZmUvcXVvdGU%2fcHI9MCZ1dG1fc291cmNlPWtlcGxlcl9ucF90bCZ1dG1fbWVkaXVtPWNtZ19ucF90bCZ1dG1fY29udGVudD1UUlVTX01WRl9URVJNeHh4X0NPVVNBX1FTX0RCX1dUUkZfVGVybXh4eHh4RF9JTUxGSV8wMDAwMDBDUENfWFhYWF9YWFhYX1FTVGllcjF4eHh4eHhfWFhYWFhfWTAwMDlfRFdYRU5fUVVJTk9BX05NRURfMDFfRU5fSlBfQ1JMaW5rcyZ1dG1fY2FtcGFpZ249JGNsaWNra2V5JF8yMDQ2NjQ4MTBfNzUwNzdfNy8yNS8xOTkzX01hbGVfJnV0bV9reGNvbmY9dHA0dWtic3kz&S=NjYyODU1&SC=NjI3Njk1NA%3d%3d&W=NQ%3d%3d&SI=MDc2ZWIzOTctZWZhOS00YTg0LWExMjMtNjhjNzlkYTMwNThm&SDT=NjM2OTc5MzEzNzAwNjc0OTUx&Z=Y2E1OWVkYmQ5MWYwN2ZkOWFiMTg2MWQ5NzFhNDA3YTc%3d&B=NjYyNjUy&L=NTY1NTc3ODE%3d&WS=U0gtTERFLVdFQjM%3d&IV=b0llZEY1UzBUSjFSTGRzZ0ZrVFJFUVRFWVk5c044clJkMWtCMzk5cjJHb2gvVWpPamV1WTBRPT0%3d&A=0&V=ZDA0bS90eVE2d1E9&QP=MjE%3d&QMPC=L3hhcGktY2FwdHVyZT9CTFVJRD0yMDE5MDcwNTExNDkxMjAyMDY5NjY0OjAyODczODQxMDowMDQmc2wucG9zPTQmc2wuY2s9Nzg2MTk5MTAmc2wuZGs9MjY5MzgwMTAmc2wuY209MTY4MzEwJnNsLmRoPTE4Mjg5MDEwJnNsLnNjPUxJRkUmc2wuY2F0PTUxNTAmc2wud2lrPTIxMjg2ODEwJnNsLmFmZj04NDYxNzM0NCZzbC5xYj0wJnNsLmVuYz0yMDQ2NjQ4MTAmc2wuaXQ9MTA5NTM2MTAmc2wuY3J0PTI1MjY0NDExMCZzbC5zZWFyY2hJZD0wNTMwZmI4ZS04NDY3LTRhYWEtOGRhZS1iOTU5M2M5NDUwOTU6OjEmc2wubWF0Y2hlZGVudGl0eWdycGtleXM9MjI1NTIwLDIxMDgyMCZ0YWc9MjA0NjY0ODEwJkNDSUQ9MjAzMjA4MDgyMDQ2NjQ4MTAmc3RhdGU9VFgmY291bnRyeT1VUyZ6aXA9NzUwNzcmc2wubXA9MUp2M2s4ZVJraFJGeGZqeElVTjFyZyUzRCUzRCZzbC5za2lwcmVkaXJlY3Q9MSY%3d&T=MQ%3d%3d",
            "imageUrl": "//qmpcdn.nextinsure.com/content/one/8400/TruStage_496x240-Tagline_72.jpg"
        }
    ]
};

const dev = {
  LEAD_POST_URL: "https://platform-leads-api-qa.azurewebsites.net/api/POST?code=CYXc9tkSnJKP1QloGmjZfwX4jg6eO223dKOAJod4SJdQvPykEmxCLA==",
  TRACKING_URL: "https://platform-leads-api-qa.azurewebsites.net/api/Track?code=CYXc9tkSnJKP1QloGmjZfwX4jg6eO223dKOAJod4SJdQvPykEmxCLA==",
  SOURCE_ONLY_URL: "https://platform-leads-api-qa.azurewebsites.net/api/Query?code=CYXc9tkSnJKP1QloGmjZfwX4jg6eO223dKOAJod4SJdQvPykEmxCLA==",
  ABANDONMENT_OFFERS_URL: "https://platform-leads-api-qa.azurewebsites.net/api/offers/abandonment?code=CYXc9tkSnJKP1QloGmjZfwX4jg6eO223dKOAJod4SJdQvPykEmxCLA==",
  LEAD_SELECTION_URL: "https://platform-leads-api-qa.azurewebsites.net/api/offers/selected?code=CYXc9tkSnJKP1QloGmjZfwX4jg6eO223dKOAJod4SJdQvPykEmxCLA==",
  EVENT_LOG_URL: "https://platform-leads-api-qa.azurewebsites.net/api/analytics?code=CYXc9tkSnJKP1QloGmjZfwX4jg6eO223dKOAJod4SJdQvPykEmxCLA==",
  REJECT_OFFERS_URL: "https://platform-leads-api-qa.azurewebsites.net/api/offers/options?code=reZwT34ts65NInjohYH3vKhdk3YsuchHpaUDCCfgo7XGcKXeuazOLA==",
  AWStrings: AWStringsQA,
  AWPixels: AWPixelsQA,
  UAStrings: UAStringsQA,
  BingAnalyticsStrings,
  debugPayload,
  debugAbandonResponse,
  debugConfirmationResponse
};

const qa = {
  LEAD_POST_URL: "https://platform-leads-api-qa.azurewebsites.net/api/POST?code=CYXc9tkSnJKP1QloGmjZfwX4jg6eO223dKOAJod4SJdQvPykEmxCLA==",
  TRACKING_URL: "https://platform-leads-api-qa.azurewebsites.net/api/Track?code=CYXc9tkSnJKP1QloGmjZfwX4jg6eO223dKOAJod4SJdQvPykEmxCLA==",
  SOURCE_ONLY_URL: "https://platform-leads-api-qa.azurewebsites.net/api/Query?code=CYXc9tkSnJKP1QloGmjZfwX4jg6eO223dKOAJod4SJdQvPykEmxCLA==",
  ABANDONMENT_OFFERS_URL: "https://platform-leads-api-qa.azurewebsites.net/api/offers/abandonment?code=CYXc9tkSnJKP1QloGmjZfwX4jg6eO223dKOAJod4SJdQvPykEmxCLA==",
  LEAD_SELECTION_URL: "https://platform-leads-api-qa.azurewebsites.net/api/offers/selected?code=CYXc9tkSnJKP1QloGmjZfwX4jg6eO223dKOAJod4SJdQvPykEmxCLA==",
  EVENT_LOG_URL: "https://platform-leads-api-qa.azurewebsites.net/api/analytics?code=CYXc9tkSnJKP1QloGmjZfwX4jg6eO223dKOAJod4SJdQvPykEmxCLA==",
  REJECT_OFFERS_URL: "https://platform-leads-api-qa.azurewebsites.net/api/offers/options?code=reZwT34ts65NInjohYH3vKhdk3YsuchHpaUDCCfgo7XGcKXeuazOLA==",
  AWStrings: AWStringsQA,
  AWPixels: AWPixelsQA,
  UAStrings: UAStringsQA,
  BingAnalyticsStrings,
  debugPayload,
  debugAbandonResponse,
  debugConfirmationResponse
};

const prod = {
  LEAD_POST_URL: "https://platform-leads-api.azurewebsites.net/api/POST?code=vJMRXZP/O3IFvdVe0kQE8Sf43U68VAUyEafMzGmCLZ2PT0fe8hc28g==",
  TRACKING_URL: "https://platform-leads-api.azurewebsites.net/api/Track?code=vJMRXZP/O3IFvdVe0kQE8Sf43U68VAUyEafMzGmCLZ2PT0fe8hc28g==",
  SOURCE_ONLY_URL: "https://platform-leads-api.azurewebsites.net/api/Query?code=vJMRXZP/O3IFvdVe0kQE8Sf43U68VAUyEafMzGmCLZ2PT0fe8hc28g==",
  ABANDONMENT_OFFERS_URL: "https://platform-leads-api.azurewebsites.net/api/offers/abandonment?code=vJMRXZP/O3IFvdVe0kQE8Sf43U68VAUyEafMzGmCLZ2PT0fe8hc28g==",
  LEAD_SELECTION_URL: "https://platform-leads-api.azurewebsites.net/api/offers/selected?code=vJMRXZP/O3IFvdVe0kQE8Sf43U68VAUyEafMzGmCLZ2PT0fe8hc28g==",
  EVENT_LOG_URL: "https://platform-leads-api.azurewebsites.net/api/analytics?code=vJMRXZP/O3IFvdVe0kQE8Sf43U68VAUyEafMzGmCLZ2PT0fe8hc28g==",
  REJECT_OFFERS_URL: "https://platform-leads-api.azurewebsites.net/api/offers/options?code=vJMRXZP/O3IFvdVe0kQE8Sf43U68VAUyEafMzGmCLZ2PT0fe8hc28g==",
  AWStrings: AWStringsProd,
  AWPixels: AWPixelsProd,
  UAStrings: UAStringsProd,
  BingAnalyticsStrings
};


var conf = {};

if (process.env.REACT_APP_STAGE === "dev") {
  conf = dev;
} else if (process.env.REACT_APP_STAGE === "qa") {
  conf = qa;
} else if (process.env.REACT_APP_STAGE === "prod") {
  conf = prod;
} else {
  conf = dev;
}

export default {
  ...conf
};