import React from "react";

const ConfirmationPageHeader = props => {
  return (
    <section id="logo">
      <article>
        <aside>
          <div className="logo"/>
        </aside>
      </article>
    </section>
  );
}

export default ConfirmationPageHeader;