class BaseService {
  axiosOptions() {
    this.opts = {};
    return this;
  }

  asGET() {
    this.opts["method"] = "GET";
    return this;
  }

  asPOST() {
    this.opts["method"] = "POST";
    return this;
  }

  withJSONHeaders(token, extraHeaders) {
    this.opts["headers"] = {
      "content-type": "application/json",
      ...(extraHeaders || {})
    };

    if (token) {
      this.opts.headers["Authorization"] = ("bearer " + token);
    }
    return this;
  }

  withURL(url) {
    if (!(url.toLowerCase()).includes("http")) {
      url = (global.baseUrl + url);
    }
    this.opts["url"] = url;
    return this;
  }

  withData(data) {
    if (typeof data === "object" && !(data instanceof FormData)) {
      this.opts["data"] = JSON.stringify(data);
    } else {
      this.opts["data"] = data;
    }
    return this;
  }

  build() {
    return this.opts;
  }

}

export default BaseService;