import OffersService from "../services/offersService";
import {
  startEvent,
  completeEvent,
  failEvent,
  getSourceForAdditionalOffersSuccessful,
  postLeadDataSuccessful
} from "../actions/events";
import {store} from "../store";
import {
  formatAdditionalHtml,
  formatDescription,
  formatHeadline
} from "../helpers";

class Offers {
  constructor(queryString, deviceType, debugMode, originalLeadId) {
    this.queryString = queryString;
    this.debugMode = debugMode;
    this.deviceType = deviceType;
    this.originalLeadId = originalLeadId;
  }

  _checkIfRunBefore = (evt = "getAbandonmentOffers") => {
    let {systemReducer} = store.getState();
    let {eventStatuses} = systemReducer;

    return eventStatuses[evt];
  }

  async getSourceForAdditionalOffers(dispatch) {
    if(this._checkIfRunBefore("getSourceForAdditionalOffers")){return;}

    dispatch(startEvent("getSourceForAdditionalOffers"));

    let results = await OffersService.getSourceForAdditionalOffers(this.queryString);

    if (results.success) {
      let {source, pixels} = results.data;
      dispatch(getSourceForAdditionalOffersSuccessful(source, pixels));
      dispatch(completeEvent("getSourceForAdditionalOffers"));
    } else {
      dispatch(failEvent("getSourceForAdditionalOffers"));
    }
  }

  async getRejectOffers(dispatch) {
    if (this._checkIfRunBefore("getRejectOffers")) {return;}

    dispatch(startEvent("getRejectOffers"));

    if (this.deviceType == "iOS13") {
      this.deviceType = "Mobile";
    }
    
    let results = await OffersService.getRejectOffers(this.queryString, this.deviceType, this.coverageAmount, this.originalLeadId, this.debugMode);

    if (results.success) {
      let {data} = results;

      if (data && data.offers) {
        data.offers.forEach((o, idx) => {
          o.headline = formatHeadline(o.headline);
          o.description = formatDescription(o.description);
          o.description = formatHeadline(o.description);
          if (o.additionalHtml) {
            o.additionalHtml = formatAdditionalHtml(o.additionalHtml, idx);
            o.url = formatAdditionalHtml(o.url, idx);
          }
        })
      }

      dispatch(completeEvent("getRejectOffers"));  
      dispatch(postLeadDataSuccessful(null, null, data.offers, null));

    } else {
      //dispatch(failEvent("getRejectOffers"));
    }

  }
}

export default Offers;