import BaseService from "./BaseService";
import axios from "axios";
import Config from "../config";

class VisitorService extends BaseService {
  constructor() {
    super();
  }

  async trackVisit(queryString) {
    try {
      const options = this.axiosOptions()
        .asPOST()
        .withJSONHeaders()
        .withURL(Config.TRACKING_URL)
        .withData({queryString, source: "Accuquote"})
        .build();

      const response = await axios(options);
      let responseData = response.data;

      if (responseData.token) {
        window.trackingToken = responseData.token;
      } else {
        window.trackingToken = null;
      }

      return {
        success: true,
        data: responseData
      };
      
    } catch(error) {
      return {
        success: false,
        error
      };
    }
  }

  async logEvent(payload) {
    try {
      const options = this.axiosOptions()
        .asPOST()
        .withJSONHeaders()
        .withURL(Config.EVENT_LOG_URL)
        .withData(payload)
        .build();

      await axios(options);

      return {
        success: true
      };
    } catch(error) {
      return {
        success: false,
        error
      };
    }
  }
}

export default new VisitorService();