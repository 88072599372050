import React, {Component} from "react";
import {Helmet} from "react-helmet";
import {pages as Desktop} from "../Components/Confirmation/Desktop";
import {pages as Tablet} from "../Components/Confirmation/Tablet";
import {pages as Mobile} from "../Components/Confirmation/Mobile";
import {connect} from "react-redux";
import UAParser from "ua-parser-js";
import Config from "../config";
import {
  selectLead,
  trackVisit,
  getSourceForAdditionalOffers,
  getRejectOffers,
} from "../actions/commands";
import ComponentSetStrategy from "../ComponentHelpers/ConfirmationPageComponentSetStrategy";

class NewRejectionOffers extends Component {
  constructor(props) {
    super(props);

    this.componentSetStrategy = new ComponentSetStrategy();
    this.uaParser = new UAParser(window.navigator.userAgent);

    this.componentLoadingStrategies = {
      Desktop: this.loadDesktopComponentStrategy,
      Tablet: this.loadTabletComponentStrategy,
      Mobile: this.loadMobileComponentStrategy
    };

    this.state = {
      componentSet: "Desktop"
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.style.opacity = 0;
    this.chooseComponentSet();
    document.body.classList.remove("landing");
    document.body.classList.add("confirmation");
    window.addEventListener("resize", this.chooseComponentSet);

    if ((process.env.REACT_APP_STAGE === "qa" || process.env.REACT_APP_STAGE === "dev") && this.props.location.search.includes("debugmode=true")) {
      this.props.postLeadData(Config.debugPayload, this.state.componentSet, true);
    }
    let showNewScript = process.env.REACT_APP_STAGE !== "prod" && window.location.href.includes("new_script=true");
    if (showNewScript) {
      window.LeadiD.snap();
    }    
  }

  waitForPageLoadCallsToFinish = platform => {
    let lId = this.getLeadId();
    var pollingInterval = setInterval(()=>{
      if (window.trackingToken) {
        let isDebug = (process.env.REACT_APP_STAGE === "qa" || process.env.REACT_APP_STAGE === "dev") && this.props.location.search.includes("debugmode=true");
        this.props.getRejectOffers(this.props.location.search, platform, "Mid", lId);
        clearInterval(pollingInterval);
      }
    }, 100);
  }

  runPageLoadApiCalls = () => {
    this.props.trackVisit(this.props.location.search);
    this.props.getSourceForAdditionalOffers(this.props.location.search);
  }

  loadDesktopComponentStrategy = () => {
    this.runPageLoadApiCalls();
    this.waitForPageLoadCallsToFinish("Desktop");
    import("../Components/Confirmation/Desktop").then(cmp => this.cmp = cmp.pages);
    import("../Assets/styles/confirmation.scss").then(()=>{
      document.body.style.opacity = 1;
      this.setState({loaded: true}); //force re-render
    });
  }

  loadTabletComponentStrategy = () => {
    this.runPageLoadApiCalls();
    this.waitForPageLoadCallsToFinish("Tablet");
    import("../Components/Confirmation/Mobile").then(cmp => this.cmp = cmp.pages);
    import("../Assets/styles/iOS13.scss").then(()=>{
    import("../Assets/styles/confirmation.mobile.scss").then(()=>{
        document.body.style.opacity = 1;
        this.setState({loaded: true}); //force re-render
      });
    });
  }

  loadMobileComponentStrategy = () => {
    this.runPageLoadApiCalls();
    this.waitForPageLoadCallsToFinish("Mobile");
    import("../Components/Confirmation/Mobile").then(cmp => this.cmp = cmp.pages);
    import("../Assets/styles/iOS13.scss").then(()=>{
    import("../Assets/styles/confirmation.mobile.scss").then(()=>{
        document.body.style.opacity = 1;
        this.setState({loaded: true}); //force re-render
      });
    });
  }

  chooseComponentSet = () => {
    this.componentSetStrategy.run();
    let componentSet = this.componentSetStrategy.getComponentSet();

    this.componentLoadingStrategies[componentSet] &&
    this.componentLoadingStrategies[componentSet]();

    this.setState({componentSet});
  }

  getLeadId = () => {
    let {search} = window.location;
    if (!search) {return null;}
    search = search.replace("?", "");
    let searchPairs = search.split("&");
    let searchHash = {};
    searchPairs.forEach(sp => {
      let tmp = sp.split("=");
      searchHash[tmp[0].toLowerCase()] = tmp[1];
    });
    return searchHash.leadid;
  }

  render() {
    let userAgentInfo = this.uaParser.getDevice();
    let deviceType = userAgentInfo.type;
    let deviceVendor = userAgentInfo.vendor;

    let width = window.outerWidth;
    if (!width || (deviceType == "mobile" && deviceVendor == "Apple")) {
      width = window.innerWidth;
    }

    let Cmp = this.cmp;
    let {componentSet} = this.state;

    return (
      <div style={{width: "100%", height: "100%"}}>
        <Helmet>
          <meta charSet="utf-8"/>
          <meta name="application-name" content="FidelityLife - Request FREE Quote"/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0, user-scalable=yes"/>
          <title>Shop me first, Shop me last!</title>
        </Helmet>
        {
          Cmp &&
          <Cmp.ConfirmationPageHeader
            maxWidth={width}
          />  
        }        
        {
          Cmp &&
          <Cmp.ConfirmationPageQuoteSection
            customMessage={"We have found these additional options in your state.  Please shop multiple companies to ensure you get the best rate"}
            hideTyMessage={true}
            maxWidth={width}
          />
        }
        {
          Cmp &&
          this.props.offers &&
          <Cmp.ConfirmationPageContentSection
            selectLead={this.props.selectLead}
            offers={this.props.offers}
          />  
        }
      </div>
    );
  } 
}

function mapStateToProps(state) {
  const {pixels, offers} = state.leadReducer;
  const {eventStatuses} = state.systemReducer;
  return {
    ...state,
    pixels,
    eventStatuses,
    offers
  };
}

export default connect(mapStateToProps, {
  selectLead,
  getRejectOffers,
  trackVisit,
  getSourceForAdditionalOffers
})(NewRejectionOffers);