import React from "react";
import ReactHtmlParser from "react-html-parser";

const ConfirmationPageContentSection = props => {
  return (
    <section id="content">
      <article id="abandon-modal-article">
        <aside>
          <div>
            <div id="SHlistings" className="shChrome shSafari">
              <div id="listingsResults">
                <div id="listingResults">
                  <table style={{width: "100%"}}>
                    <tbody style={{backgroundColor: "white"}}>
                      {/*begin row*/}
                      {
                        props.offers &&
                        props.offers.map((i, k) => {
                          return (
                            <div
                              onClick={() => {
                                props.selectLead(i.id);
                                window.open(i.url);
                              }}
                              style={{border: "1px solid #ededed", margin: "0 0 10px 0", padding: "5px 0", cursor: "pointer"}}
                            >
                              <table style={{width: "100%"}}>
                                <tbody>
                                  <tr>
                                    <td style={{width: "30%"}}>
                                      <div
                                        className="listingLogo"
                                        style={{
                                          width: 160,
                                          margin: "0 auto",
                                          cursor: "pointer"
                                        }}
                                      >
                                        <div
                                          id="listingLogoWrapper0"
                                          className="listingLogoWrapper"
                                          style={{
                                            border: "1px solid #ededed",
                                            padding: 15
                                          }}
                                        >
                                          <a>
                                            <img
                                              style={{width: 120}}
                                              src={i.imageUrl}
                                              alt=""
                                              className="adLogo"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                    <td id="quote-title" style={{width: "50%"}}>
                                      <span                                        
                                        style={{
                                          color: "rgb(93, 142, 213)",
                                          fontWeight: "bold",
                                          fontSize: 16,
                                          cursor: "pointer"
                                        }}
                                      >
                                        {i.headline}
                                      </span>
                                      <div>
                                        <div className="listingDescriptionBody">
                                          {
                                            ReactHtmlParser(i.description)
                                          }
                                          <div style={{display: "none"}}>
                                            {
                                              ReactHtmlParser(i.additionalHtml)
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td style={{width: "20%", textAlign: "center"}}>
                                      <div className="cstmBtn">
                                        <span className="adGoBtn">
                                          <span className="adGoBtnTxt">GET QUOTE</span>
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>                            
                          );
                        })
                      }                      
                      {/*end row*/}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </aside>
      </article>
    </section>
  );
}


export default ConfirmationPageContentSection;