import * as types from "../actions/events";

let leadState = {
  clickId: "-1",
  formClicked: false
};

const leadReducer = (state = leadState, action) => {
	switch (action.type) {
    
    case types.POST_LEAD_DATA_SUCCESS:
      return {
        ...state,
        pixels: action.pixels,
        source: action.source,
        offers: action.offers,
        postLeadDataValidationErrors: null,
        leadIdToken: action.leadIdToken
      };

    case types.FAILED_TO_POST_LEAD_DATA:
      return {
        ...state,
        pixels: null,
        source: null,
        postLeadDataValidationErrors: action.errors
      };

    case types.GET_SOURCE_FOR_ADDITIONAL_OFFERS_SUCCESS:
      return {
        ...state,
        alternateOffersBrokerSource: action.alternateOffersBrokerSource,
        landingPagePixels: action.landingPagePixels
      };

    case types.GET_ABANDONMENT_OFFER_DATA_SUCCESS:
      return {
        ...state,
        abandonmentOfferData: action.abandonmentOfferData
      };

    case types.SET_CLICK_ID:
      return {
        ...state,
        clickId: action.clickId
      };

    case types.FIELD_FOCUSED:
      return {
        ...state,
        formClicked: true
      };
      
    default:
      return state;
  }
};

export default leadReducer;