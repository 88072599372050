import VisitorService from "../services/visitorService";
import {
  startEvent,
  completeEvent,
  failEvent
} from "../actions/events";
import {store} from "../store";
import UAParser from "ua-parser-js";

class Visitor {
  constructor() {
    let userAgentInfo = new UAParser(window.navigator.userAgent);
    
    let deviceType = userAgentInfo.getDevice().type;
    let osName = userAgentInfo.getOS().name;
    let osVersion = userAgentInfo.getOS().version;
    let browserName = userAgentInfo.getBrowser().name;
    let browserVersion = userAgentInfo.getBrowser().version;


    if (!deviceType) {deviceType = "Desktop"}
    else if (deviceType == "tablet") {deviceType = "Tablet"}
    else {deviceType = "Mobile"}

    this.deviceType = deviceType;
    this.OS = `${osName} ${osVersion}`;
    this.device = userAgentInfo.getDevice().model;
    this.browser = `${browserName} ${browserVersion}`;
    this.eventLog = [];
    this.singleRunEvents = ["pageRendered"];
  }

  _checkIfRunBefore = (evt = "trackVisit") => {
    let {systemReducer} = store.getState();
    let {eventStatuses} = systemReducer;

    return eventStatuses[evt];
  }

  async track(queryString, dispatch) {
    if(this._checkIfRunBefore("trackVisit")){return;}

    dispatch(startEvent("trackVisit"));

    let results = await VisitorService.trackVisit(queryString);

    if (results.success) {

      dispatch(completeEvent("trackVisit"));
    } else {
      dispatch(failEvent("trackVisit"));
    }
  }

  async logEvent(eventType, value, dispatch) {

    /*dispatch(startEvent("logEvent"));
    let eventDate = new Date();
    let hour = eventDate.getHours() + "";
    let minute =  eventDate.getMinutes() + "";
    let second = eventDate.getSeconds() + "";
    let eventDateString = (eventDate.getFullYear() + "-" + (eventDate.getMonth() + 1) + "-" + eventDate.getDate() + "T" + hour.padStart(2, "0") + ":" + minute.padStart(2, "0") + ":" + second.padStart(2, "0") + "." + eventDate.getMilliseconds());
    
    let horizontal = window.outerWidth;
    let vertical = window.outerHeight;

    if ((horizontal == 0 || vertical == 0) && this.deviceType != "Desktop") {
      horizontal = window.screen.width;
      vertical = window.screen.height;
    } else if (horizontal == 0 || vertical == 0) {
      var wholeDocument = document.getElementsByTagName("html")[0];
      horizontal = wholeDocument.clientWidth;
      vertical = wholeDocument.clientHeight;
    }
    
    let resolution = window.matchMedia("(orientation: portrait)").matches || this.deviceType == "Desktop"  ?  (horizontal + "x" + vertical) : (vertical + "x" + horizontal);

    let payload = {
      source: "Accuquote",
      eventType,
      timestamp: eventDateString,
      deviceType: this.deviceType,
      resolution,
      uniqueId: window.uniqueVisitorId,
      vendor: `${window.navigator.vendor} ${this.device || ""} ${this.browser}`,
      operatingSystem: this.OS,
      queryString: window.location.search,
      url: window.location.pathname
    };

    if (value != undefined) {
      payload.value = value;
    }

    let results = await VisitorService.logEvent(payload);

    if (results.success) {
      dispatch(completeEvent("trackVisit"));
    } else {
      dispatch(failEvent("trackVisit"));
    }*/ 
    dispatch(completeEvent("logEvent")); //disabling logging
  }
}

export default Visitor;