import React, {Component} from 'react';
import {store} from "./store";
import {Provider} from "react-redux";
import {Router} from "react-router";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import LandingPage from "./Pages/LandingPage";
import ConfirmationPage from "./Pages/ConfirmationPage";
import ConfirmationPage2 from "./Pages/ConfirmationPage2";
import PrivacyPage from "./Pages/PrivacyPolicy";
import {createBrowserHistory} from "history";
import BingAnalytics from "./analytics/Bing";
import YahooAnalytics from "./analytics/Yahoo";
import GoogleAnalytics from "./analytics/Google";
import getSiteType from "./analytics/GetSiteType";
import UAParser from "ua-parser-js";
import Config from "./config";
import TermsAndConditions from "./Pages/TermsAndConditions";
//import LogRocket from 'logrocket';
import RootLevelErrorBoundary from "./Components/ErrorBoundaries/RootLevelErrorBoundary";
//import "./index.css";
import RejectionLanding from "./Pages/RejectionLanding";

const history = createBrowserHistory();

class App extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    history.listen((location, action) => {
      this.googleAnalytics.trackPageView();
      this.bingAnalytics.initializeAndTrack();

      if (location.pathname == "/lead/confirmation") {
        this.googleAnalytics.event("conversion", {send_to: `${Config.AWStrings.default}/${Config.AWPixels.default}`});
        this.googleAnalytics.event("conversion", {send_to: `${Config.AWStrings.fidelity}/${Config.AWPixels.fidelity}`});
      }

    });
    if (window.navigator.userAgent.includes("Trident")) {
      document.body.classList.add("isIE11");
    }    
  }

  componentDidMount() {
    /*let uaParser = new UAParser(window.navigator.userAgent);
    let deviceType = uaParser.getDevice().type;
    this.type = getSiteType(deviceType).siteType;*/

    this.googleAnalytics = new GoogleAnalytics(Config.UAStrings.default, [Config.AWStrings.default, Config.AWStrings.fidelity]);

    this.googleAnalytics.initialize();

    this.bingAnalytics = new BingAnalytics(Config.BingAnalyticsStrings.default);
    this.bingAnalytics.initializeAndTrack();

    //LogRocket.init('ufgyus/accuquote');
  }  

  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route
              history={history}
              path="/"
              exact
              component={e => 
                <RootLevelErrorBoundary>
                  <LandingPage {...e}/>
                </RootLevelErrorBoundary>
              }
            />
            <Route
              history={history}
              path="/final-expense"
              exact
              component={LandingPage}
            />
            <Route
              history={history}
              path="/lead/confirmation"
              exact
              component={ConfirmationPage2}
            />
            <Route
              history={history}
              path="/privacy"
              exact
              component={PrivacyPage}
            />
            <Route
              history={history}
              path="/legal"
              exact
              component={TermsAndConditions}
            />
            <Route
              history={history}
              path="/altcoverage"
              exact
              component={RejectionLanding}
            />
            <Route
              history={history}
              component={LandingPage}
            />            
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
