const searchSiteRoutes = [
  "why-choose-fidelity-life",
  "how-much-insurance",
  "rapid-decision-term-life",
  "senior-life"
];

const finalExpenseSiteRoutes = [
  "final-expense-default",
  "final-expense-senior-life",
  "final-expense-accidental-death",
  "final-expense-why-choose-fidelity-life"
];

const types = {
  DEFAULT: "default",
  FINAL_EXPENSE: "finalexpense",
  QUOTE: "quote"
};

export default function getSiteType(deviceType){
  let siteType = types.DEFAULT;
  let routeType = null;

  let url = window.location.href;

  if (url.includes("ppc")) {
    let cleanedUpPath = url.substring((url.indexOf("ppc/") + 4));
    let endIndex = cleanedUpPath.length;
    if (cleanedUpPath.includes("/")) {
      endIndex =  cleanedUpPath.indexOf("/");
    } else if (cleanedUpPath.includes("?")) {
      endIndex = cleanedUpPath.indexOf("?");
    }
    cleanedUpPath = cleanedUpPath.substring(0, endIndex);
    if (searchSiteRoutes.includes(cleanedUpPath)) {
      siteType = types.QUOTE;
      routeType = cleanedUpPath;
    } else if (finalExpenseSiteRoutes.includes(cleanedUpPath)) {
      siteType = types.FINAL_EXPENSE;
      routeType = cleanedUpPath;
    }
  } else if(url.includes("getfidelitylife") && deviceType == "mobile") {
    siteType = types.QUOTE;
  } else {
    let cleanedUpDomainName = url.replace("http://", "");
    //cleanedUpDomainName = cleanedUpDomainName.replace("https://", "");
    //cleanedUpDomainName = cleanedUpDomainName.substring(0, (cleanedUpDomainName.includes("/") ? cleanedUpDomainName.indexOf("/") : cleanedUpDomainName.indexOf("?")));
    if (cleanedUpDomainName.includes("final-expense")) {
      siteType = types.FINAL_EXPENSE;
    } else if (url.includes("utm_medium") || url.includes("utm_source")) {
      siteType = types.QUOTE;
    }
  }

  return {
    siteType,
    routeType
  };
}