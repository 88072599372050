import * as types from "../actions/events";
import {EventStatuses} from "../system";

let systemState = {
  eventStatuses: {},
  locationServicesEnabled: true
};

const systemReducer = (state = systemState, action) => {
  switch (action.type) {
    case types.EVENT_STATUS_CHANGE:
      var {eventStatuses} = state;
      eventStatuses[action.eventName] = action.eventStatus;

      if (eventStatuses[action.eventName] === EventStatuses.ACKNOWLEDGED) {
        delete eventStatuses[action.eventName];
      }

      return {
        ...state,
        eventStatuses
      };
    
    default:
      return state;
  }
};

export default systemReducer;