import React, {Component} from "react";

const linkStyle = {
    cursor:"pointer",
    color:"blue",
    textDecoration:"underline"
};

class PrivacyPolicy extends Component {
  componentDidMount() {
    document.body.style.opacity = 1;
    document.body.classList.remove("confirmation");
    document.body.classList.add("landing");
    document.body.classList.add("desktop");
  }

  render() {
    return (
      <div style={{width: "100%", height: "100%"}}>
        <section className="logo legaltext">
          <article>
            <aside>
              <div className="f-logo">ACCUQUOTE</div>
            </aside>
          </article>
        </section>
        <section className="legaltext">
          <article>
            <aside>
              <h1>Live Life Insurance Privacy Policy Notice</h1>
            <p>Effective 1/30/2020</p>
            <h2>Please read these terms and conditions of use carefully before using this website.</h2>
            <p>Thank you for visiting accuquoteinsurance.com (“Website,” “Site,” or “App”). The Site is owned and operated by  Life Story Insurance Agency, LLC. (dba "Live Life Insurance” “we,” “us,” or “our”). This privacy policy (the “Privacy Policy”) describes the information that we collect from you when you use our Site or our Services and what we do with this information. By accessing or using the Site or any of the Services, you hereby agree to the terms of this Privacy Policy and the <a onClick={()=>this.props.history.push("/legal")} style={linkStyle}>Terms & Conditions of Use</a> governing your use of the Site and the Services, into which this Privacy Policy is incorporated by reference. If you do not agree with this Privacy Policy or the Terms of Use, then please do not use the Site or the Services. Capitalized terms that are used in this Privacy Policy but not defined shall have the meanings ascribed to them in the Terms of Use.</p>
            <p>The Privacy Policy is effective as of the last updated date specified above. We may change this Privacy Policy at any time. Please check this page periodically for changes as your continued use of the Site or Services after any such change will constitute your acceptance of any modified terms.</p>
            <h2>Information we collect</h2>
            <h3>Personal Information You Provide</h3>
            <p>We collect the content and other personal information that may be used to identify you as an individual you provide when you use our Services, including when you request an insurance quote, apply for insurance, and sign up for an account. This can be personally identifiable information, including health information. We also collect information about how you use our Services, such as the types of content you view or engage with or the frequency and duration of your activities. Depending on the nature of the Services provided by us to our customers, we may receive personal information either from you directly or from third party partners.</p>
            <p>The personal information we may collect includes, but is not limited to: name, address, date of birth, phone number, email address, height, weight, personal and family health information.</p>
            <h3>Device</h3>
            <p>We collect information from or about the computers, phones, or other devices where you install or access our Services, depending on the permissions you’ve granted. We may associate the information we collect from your different devices, which helps us provide consistent Services across your devices.</p>
            <p>This includes, but is not limited to: attributes such as the operating system, hardware version, device settings, file and software names and types, battery and signal strength, and device identifiers. Device locations, including specific geographic locations, such as through GPS, Bluetooth, or WiFi signals. Connection information such as the name of your mobile operator or ISP, browser type, language and time zone, mobile phone number and IP address.</p>
            <h3>Information from third-party partners.</h3>
            <p>We receive information about you and your activities on and off the site from third-party partners, such as information from a partner when we jointly offer services or from an advertiser about your experiences or interactions with them.</p>
            <p>Third party information we collect includes, but is not limited to: Google Analytics, Google Tag Manager, Facebook Pixel, Twitter Pixel, Lucky Orange, Criteo Pixel.</p>
            <h3>Cookies and tracking technology.</h3>
            <p>We collect information from tracking technology such as Cookies and Web Beacons. Cookies are small pieces of text used to store information on web browsers. Cookies are used to store and receive identifiers and other information on computers, phones, and other devices. Other technologies, including data we store on your web browser or device, identifiers associated with your device, and other software, are used for similar purposes. In this policy, we refer to all of these technologies as “cookies.” Web Beacons are electronic files used to track your navigation on a website or collection of sites, the completion of transactions, submittal of applications and browsing behavior. We use these technologies to enhance your shopping experience. To opt out of any personalized advertising please send us an email at <a href="mailto:unsubscribe@accuquote-offer.com">unsubscribe@accuquote-offer.com</a>. You can always turn off all cookies via your browser settings, however if you do so, some features of this Site might not function properly.</p>
            <h2>How we use information</h2>
            <p>We collect all of the information described in this Privacy Policy to provide you with the best experience possible in relation to insurance and financial services. We are passionate about personalized experiences. We use all of the information we have to help us provide and support our Services. Possible uses or information include:</p>
            <p>Provision of the Services; provide accurate product recommendations and quotes, help first-and-third-party underwriting; provide information for applications; provide or offer other insurance, financial and consumer products and services, possibly from third-parties; provide, maintain and improve customer service; customize and personalize your experience with the Services to your interests; develop and improve the Site and the Services; respond to your inquiries; and communicate with you regarding your purchases or any other related topics. In addition, we may aggregate your information with our other user’s information and use that information for benchmarking and analysis purposes.</p>
            <p>By submitting your personal information either to us or to any of your partners, you consent to the use of your personal information as set forth above, and to the transfer of that data to our partners or those individuals or entities we engage to provide Services in connection with your transaction.</p>
            <p>We communicate with you through one or more of the following means: phone including an automated dialer, SMS, physical mail, email, notices posted on the Live Life Insurance websites or apps, messages to your Live Life Insurance inbox, and other means available through the Services, including mobile text messages and push notifications. Examples of these communications include: transactional communications, welcome and engagement communications, service communications, promotional communications, and sales communications.</p>
            <p>Your prior written consent is required for any artificial voice or message to your residential phone or any artificial voice or prerecorded message or an automatic telephone dialing system to your mobile phone in each instance that offers or markets our products or Services to consumers or that have a telemarketing purpose. We may obtain your prior written consent when you do the following: sign up for an account for our Services and opt-in to receive such phone calls (see our <a style={linkStyle} onClick={()=>this.props.history.push("/Legal")}>Terms of Use Policy</a>), respond to our text message requesting if you wish to opt-in, respond to our telephone call via a keypress or voice recording. You may opt-out at any time.</p>
            <p>Please be aware that you cannot opt out of receiving service only messages from us. We take your information and privacy very seriously and strive for 100% CAN-SPAM and TCPA compliance, please contact us directly at <a href="mailto:privacy@accuquoteinsurance.com">Privacy</a> if you discover any issues.</p>
            <h2>Information sharing</h2>
            <p>In some circumstances, we may share your information with third parties such as insurance carriers, brokerages, and agents offering products and information made available to you through the Services in order to fulfill a product or service. This includes any parent companies, subsidiaries, join ventures, or other companies under common control with us. These companies may then contact you outside of the Service via automated dialer, SMS, or email, without your direct knowledge or a pre-set appointment.</p>
            <p>We may use third party vendors to assist us with the Service. Some examples of the assistance that may be provided by third party service providers are: monitoring and analyzing Site activity, operations and marketing, hosting the Site, and maintaining our database. It is possible that some of these service providers will be located outside of the United States. We may share your personal information with these third parties but only as necessary for them to perform their functions and to the extent permitted by law. Use of your personal information by these third party vendors is governed by their own privacy policies and procedures and not by this Privacy Policy.</p>
            <p>As we develop our businesses, we might sell or buy other’s businesses or assets. In the event of a corporate sale, merger, reorganization, sale of assets, dissolution, or similar event, some or all of your personal information may be part of the transferred assets.</p>
            <p>To the extent permitted by law, we may also disclose your personal information when required by law, valid court order or subpoena; to cooperate with law enforcement or other governmental investigations (without necessarily requiring the law enforcement or government agency requesting the information to formally serve us with a subpoena); when we believe it is needed for fraud protection and/or credit risk reduction; and/or whenever we believe that disclosing such information is necessary or advisable, for example, to protect the rights, property, or safety of us or others. We also reserve the right to report to such entities any activities that we believe to be unlawful. Any report of such activities will be made without prior notice to you, and you consent to such disclosure by us.</p>
            <p>We are not in the business of selling, trading or renting personal information. We will not use or share your personally identifiable information with others except those individuals or entities which have a business relationship with us.</p>
            <h2>Security</h2>
            <p>We implement security measures to maintain the safety of your personal information when you enter or submit your personal information. We follow generally accepted industry standards to protect the information submitted to us, both during transmission and once we receive it. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, while we strive to protect your personal information and we make all commercially reasonable efforts to do so, we cannot guarantee the security of any information you transmit to us, and you provide such information at your own risk. Once we receive your personal information, we make our best efforts to ensure its security on our systems.</p>
            <h2>Children</h2>
            <p>We do not knowingly collect personal information from children under the age of 13. If you are under 13, please do not give us any personal information. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce this Privacy Policy by instructing their children to never provide personal information through Internet without their permission. We store information of children under 13 if submitted by a legal guardian for example if a child is on a health insurance policy, or if a child is a beneficiary.</p>
            <h2>External sites</h2>
            <p>This Privacy Policy applies to all information that you provide on the site. We may place links to third party websites and may include information and links to third party websites in our emails. When you visit websites to which ours links, keep in mind you will be interacting with a third party that operates under its own privacy and security policy. If you choose to obtain certain services through a third party site, the data collected by the third party is governed by that third party’s privacy policy. A third party site will also have its own policy regarding the use of cookies and clear gifs. We will not be responsible for the actions of any third parties. We encourage you to review the privacy policies of any other service provider from whom you request services.</p>
            <h2>Questions</h2>
            <p>If you have any questions, or would like additional information about the Privacy Policy, please contact us at <a href="mailto:privacy@accuquoteinsurance.com">privacy@accuquoteinsurance.com</a>.</p>
            <p><em>AccuQuote, brought to you by Live Life Insurance<br/>13770 Noel Rd. #800076 <br/> Dallas TX 75380</em></p>
            </aside>
          </article>
        </section>
      </div>
    );
  }
}

export default PrivacyPolicy;