import UAParser from "ua-parser-js";

class LandingPageComponentSetStrategy {
  constructor() {
    this.uaParser = new UAParser(window.navigator.userAgent);
    this.componentSet = "";
    this.windowWidth = window.screen.width;
    this.windowHeight = window.screen.height;
  }

  run = () => {
    let browserInfo = this.uaParser.getBrowser();
    let userAgentInfo = this.uaParser.getDevice();
    let deviceType = userAgentInfo.type;
    let deviceVendor = userAgentInfo.vendor;

    let browserVersion = browserInfo.major;
    let browserName = browserInfo.name;

    let windowWidth = window.screen.width;
    let windowHeight = window.screen.height;
    let orientation = window.orientation;

    this.componentSet = "";
    //this.componentSet = "iOS13";
    //document.body.style["font-size"] = null;
    if (!deviceType) {
      document.body.style["font-size"] = null;
      document.body.classList.remove("tablet");
      document.body.classList.remove("mobile");
      document.body.classList.add("desktop");
      this.componentSet = "Desktop";
    } else if (deviceType == "tablet") {      
      document.body.style["font-size"] = null;
      document.body.classList.remove("desktop");
      document.body.classList.remove("mobile");
      document.body.classList.add("tablet");
      this.componentSet = "Tablet";
    } else {
      document.body.style["font-size"] = null;
      this.componentSet = "iOS13";
    }

  }

  getComponentSet = () => {
    return this.componentSet;
  }
}

export default LandingPageComponentSetStrategy;