
class GoogleAnalytics {
  constructor(analyticsId, adwordsIds = []) {
    this.analyticsId = analyticsId;
    this.adwordsIds = adwordsIds;
  }

  initialize = () => {
    var baseScript = document.createElement("script");
    baseScript.async = true;
    baseScript.src = `https://www.googletagmanager.com/gtag/js?id=${this.analyticsId}`;

    var initScript = document.createElement("script");
    initScript.text = `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${this.analyticsId}');`;

    document.body.appendChild(baseScript);
    document.body.appendChild(initScript);

    this._initializeAdwords();
  }

  _initializeAdwords = () => {
    let {gtag} = window;
    this.adwordsIds.forEach(awId => {
      gtag && gtag("config", awId);
    });
  }

  trackPageView = () => {
    let {gtag} = window;
    gtag && gtag("config", this.analyticsId, {"page_location": window.location.href, "page_path": window.location.pathname});
    this.adwordsIds.forEach(awId => {
      gtag && gtag("config", awId);
    });
  }

  event = (eventName, params) => {
    let {gtag} = window;
    gtag && gtag("event", eventName, params);
  }
}

export default GoogleAnalytics;