import Lead from "../domain/Lead";
import Offers from "../domain/Offers";
import Visitor from "../domain/Visitor";

export const trackVisit = (queryString) => {
  return dispatch => {
    if (!global.visitor) {
      global.visitor = new Visitor();
    }
    global.visitor.track(queryString, dispatch);
  };
};

export const logEvent = (eventType, value) => {
  return dispatch => {
    if (!global.visitor) {
      global.visitor = new Visitor();
    }
    global.visitor.logEvent(eventType, value, dispatch);
  };
};

export const postLeadData = (leadDataPayload, deviceType, debugMode) => {  
  return dispatch => {
    let lead = new Lead(leadDataPayload, deviceType, debugMode);
    lead.post(dispatch);
  };
};

export const getSourceForAdditionalOffers = queryString => {
  return dispatch => {
  	let offers = new Offers(queryString);
  	offers.getSourceForAdditionalOffers(dispatch);
  };
};

export const selectLead = leadId => {
  return dispatch => {
    let lead = new Lead({});
    lead.selectLead(leadId, dispatch);
  };
};

export const getRejectOffers = (queryString, deviceType, coverageAmount, originalLeadId) => {
  return dispatch => {
    let offers = new Offers(queryString, deviceType, coverageAmount, originalLeadId);
    offers.getRejectOffers(dispatch);
  };
};