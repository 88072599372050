import LeadService from "../services/leadService";
import {
  startEvent,
  completeEvent,
  failEvent,
  postLeadDataSuccessful,
  failedToPostLeadData
} from "../actions/events";
import moment from "moment";
import {
  formatAdditionalHtml,
  formatDescription,
  formatHeadline
} from "../helpers";

class Lead {
  constructor({firstName, lastName, gender, zip, dobMonth, dobDay, dobYear, phone, email, address, queryString, leadIdToken}, deviceType, isDebug) {
    this.leadData = {
      firstName,
      lastName,
      gender,
      zip,
      dobMonth,
      dobDay,
      dobYear,
      phone,
      email,
      queryString,
      leadIdToken,
      address
    };
    this.deviceType = deviceType;
    this.isDebug = isDebug;

    this.daysForDates = {
      "01": 31,
      "02": 29,
      "03": 31,
      "04": 30,
      "05": 31,
      "06": 30,
      "07": 31,
      "08": 31,
      "09": 30,
      "10": 31,
      "11": 30,
      "12": 31
    };
  }

  validate = () => {
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validationErrors = [];
    let {leadData} = this;

    if (!leadData.firstName) {
      validationErrors.push({
        field: "firstName",
        reason: "invalid"
      });
    }
    if (!leadData.lastName) {
      validationErrors.push({
        field: "lastName",
        reason: "invalid"
      });
    }
    if (!leadData.zip || isNaN(parseInt(leadData.zip)) || String(leadData.zip).length < 5) {
      validationErrors.push({
        field: "zip",
        reason: "invalid"
      });
    }
    if (!leadData.dobMonth || !/^\d+$/.test(leadData.dobMonth) || (parseInt(leadData.dobMonth) > 9 && leadData.dobMonth.length < 2) || parseInt(leadData.dobMonth) < 1 || parseInt(leadData.dobMonth) > 12) {      
      validationErrors.push({
        field: "dobMonth",
        reason: "invalid"
      });
      validationErrors.push({
        field: "dobDay",
        reason: "invalid"
      });
      validationErrors.push({
        field: "dobYear",
        reason: "invalid"
      });
    }
    if (!leadData.dobDay || !/^\d+$/.test(leadData.dobDay) || (parseInt(leadData.dobDay) > 9 && leadData.dobDay.length < 2) || parseInt(leadData.dobDay) < 1 ||  parseInt(leadData.dobDay) > this.daysForDates[(leadData.dobMonth.length < 2 ? `0${leadData.dobMonth}` : leadData.dobMonth)]) {
      validationErrors.push({
        field: "dobMonth",
        reason: "invalid"
      });
      validationErrors.push({
        field: "dobDay",
        reason: "invalid"
      });
      validationErrors.push({
        field: "dobYear",
        reason: "invalid"
      });
    }
    if (!leadData.dobYear || !/^\d+$/.test(leadData.dobYear) || leadData.dobYear.length < 4) {
      validationErrors.push({
        field: "dobMonth",
        reason: "invalid"
      });
      validationErrors.push({
        field: "dobDay",
        reason: "invalid"
      });
      validationErrors.push({
        field: "dobYear",
        reason: "invalid"
      });
    }
    if (!validationErrors.includes("dobMonth") && !validationErrors.includes("dobDay") && !validationErrors.includes("dobYear")) {
      let userAge = moment().diff(moment(`${leadData.dobMonth}/${leadData.dobDay}/${leadData.dobYear}`, "MM/DD/YYYY"), "years");
      if (userAge < 18 || userAge > 120) {
        validationErrors.push({
          field: "dobMonth",
          reason: "invalid"
        });
        validationErrors.push({
          field: "dobDay",
          reason: "invalid"
        });
        validationErrors.push({
          field: "dobYear",
          reason: "invalid"
        });
      }
    }
    if (!leadData.phone || leadData.phone.length < 10 || isNaN(parseInt(leadData.phone)) || String(parseInt(leadData.phone)).length < 10) {
      validationErrors.push({
        field: "phone",
        reason: "invalid"
      });
    }
    if (!leadData.email || !leadData.email.includes("@") || !emailRegex.test(leadData.email.toLowerCase())) {
      validationErrors.push({
        field: "email",
        reason: "invalid"
      });
    }
    // if (!leadData.address) {
    //   validationErrors.push({
    //     field: "address",
    //     reason: "invalid"
    //   }); 
    // }
    return validationErrors;
  }

  cleanEmail = email => {
    while(email && email.indexOf(" ") > -1) {
      email = email.replace(" ", "");
    }
    return email;
  }

  async post(dispatch) {
    dispatch(startEvent("postLeadData"));
    let {leadData} = this;

    leadData.email = this.cleanEmail(leadData.email);

    let validationResults = this.validate();

    if (validationResults.length > 0) {
      dispatch(failEvent("postLeadData"));
      dispatch(failedToPostLeadData(validationResults));
      return;
    }

    leadData.dateOfBirth = `${leadData.dobMonth}-${leadData.dobDay}-${leadData.dobYear}`;
    leadData.leadId_token = leadData.leadIdToken;

    if (this.deviceType == "iOS13") {
      this.deviceType = "Mobile";
    }

    delete leadData.dobMonth;
    delete leadData.dobDay;
    delete leadData.dobYear;
    delete leadData.leadIdToken;

    let results = await LeadService.postLeadData(leadData, this.deviceType, this.isDebug);

    if (results.success) {
      let {pixels, source, offers, token} = results.data;

      if (offers) {
        offers.forEach((o, idx) => {
          o.headline = formatHeadline(o.headline);
          o.description = formatDescription(o.description);
          o.description = formatHeadline(o.description);
          if (o.additionalHtml) {
            o.additionalHtml = formatAdditionalHtml(o.additionalHtml, idx);
            o.url = formatAdditionalHtml(o.url, idx);
          }
        })
      }

      dispatch(completeEvent("postLeadData"));  
      dispatch(postLeadDataSuccessful(pixels, source, offers, token));
    } else {      
      dispatch(failedToPostLeadData(results.errors));
      dispatch(failEvent("postLeadData"));
    }      
  }

  async selectLead(leadId, dispatch) {
    dispatch(startEvent("selectLead"));

    let results = await LeadService.selectLead(leadId);

    if (results.success) {
      dispatch(completeEvent("selectLead"));
    } else {
      dispatch(failEvent("selectLead"));
    }
  }
}

export default Lead;