
class LeadIdScriptSetup {

  attachNormalScript = () => {
    /*window.LeadiD = null;
    let existingScript = document.getElementById("LeadiDscript_campaign");
    let existingInput = document.getElementById("leadid_token");

    if(existingInput) {
      document.body.removeChild(existingInput);
    }
    if (existingScript) {
      document.body.removeChild(existingScript);
    }*/

    let showNewScript = process.env.REACT_APP_STAGE !== "prod" && window.location.href.includes("new_script=true");

    let input = document.createElement("input");
    input.type="hidden";
    input.id="leadid_token";
    //input.name = "universal_leadid";
    input.name = "leadid_token";


    let script = document.createElement("script");

    if (showNewScript) {
      script.id = "LeadiDscript_campaign";
      script.async = true;
      //script.src = "//create.lidstatic.com/campaign/928d691b-feed-beef-cafe-e8a185b440e9.js?snippet_version=2";
      //script.src = "//create.lidstatic.com/campaign/a0871f89-face-feed-cafe-d6a53d431b14.js?snippet_version=2";
      script.src = "//create.lidstatic.com/campaign/a0871f89-face-feed-cafe-d6a53d431b14.js?snippet_version=2";
    } else {
      script.id = "LeadiDscript_campaign";
      script.async = true;
      script.src = "https://leadid-cdn.azureedge.net/88f48b63-fdbb-80e8-819e-01beefcf53b0.js";
      //script.src = "https://d1tprjo2w7krrh.cloudfront.net/campaign/88f48b63-fdbb-80e8-819e-01beefcf53b0.js"
      //script.src = "//create.lidstatic.com/campaign/88f48b63-fdbb-80e8-819e-01beefcf53b0.js?snippet_version=2";
    }    

    document.body.appendChild(input);
    document.body.appendChild(script);
  }
}

export default LeadIdScriptSetup;