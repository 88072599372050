
class BingAnalytics {
	constructor(id) {
		this.id = id;
	}

	initializeAndTrack = () => {
		let existingScript = document.getElementById(this.id);
		let existingScript2 = document.getElementById("bing-script");
		if (existingScript) {
			document.body.removeChild(existingScript);
		}
		if (existingScript2) {
			try {
				document.body.removeChild(existingScript2);
			} catch (error) {

			}
			try {
				document.head.removeChild(existingScript2);
			} catch (error) {

			}
			
		}

		let script = document.createElement("script");
		script.id = this.id;
		script.type = "text/javascript";
		script.text = `(function (w, d, t, r, u) {
    	var f, n, i; w[u] = w[u] || [], f = function () {
      var o = { ti: "${this.id}" }; o.q = w[u], w[u] = new UET(o), w[u].push("pageLoad")
    }, n = d.createElement(t), n.id="bing-script", n.src = r, n.async = 1, n.onload = n.onreadystatechange = function () {
    var s = this.readyState; s && s !== "loaded" && s !== "complete" || (f(), n.onload = n.onreadystatechange = null)
   	}, i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i)
  	})(window, document, "script", "//bat.bing.com/bat.js", "uetq");
		`;
		document.body.appendChild(script);
	}
}

export default BingAnalytics;