import React, {Component} from "react";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import {
  trackVisit,
  postLeadData,
  getSourceForAdditionalOffers,
  logEvent
} from "../actions/commands";
import {
  acknowledgeEvent,
  setClickId,
  fieldFocused
} from "../actions/events";
import {EventStatuses} from "../system";
import {uniq} from "lodash";
import UAParser from "ua-parser-js";
import ComponentSetStrategy from "../ComponentHelpers/LandingPageComponentSetStrategy";
import {getClickId} from "../helpers";
import LeadIdComp from "../Components/LeadIdComp";
import getSiteType from "../analytics/GetSiteType";
import queryString, { parse } from "query-string";

export class LandingPage extends Component {  
  constructor(props) {
    super(props);
    this._submitButtonClicked = false;
    this._isMounted = false;

    this.componentSetStrategy = new ComponentSetStrategy();
    this.uaParser = new UAParser(window.navigator.userAgent);

    this.componentLoadingStrategies = {
      Desktop: this.loadDesktopComponentStrategy,
      Tablet: this.loadTabletComponentStrategy,
      Mobile: this.loadiOS13ComponentStrategy,
      iOS13: this.loadiOS13ComponentStrategy
    };

    this.siteData = getSiteType(this.uaParser.getDevice().type);
    this.type = this.siteData.siteType;

    let isAffiliate = this.props.location.search && this.props.location.search.includes("o=");
    let isSearch = this.props.location.search && (this.props.location.search.includes("utm_medium") || this.props.location.search.includes("utm_source"));
    let isFinalExpense = this.props.location.pathname && this.props.location.pathname.includes("final-expense");

    if (isFinalExpense) {
      document.body.classList.add("finalexpense");
      document.body.classList.remove("affiliate");
      document.body.classList.remove("search-type");
    } else if (isAffiliate) {
      document.body.classList.add("affiliate");
      document.body.classList.remove("search-type");
      document.body.classList.remove("finalexpense");
    } else if (isSearch) {
      document.body.classList.add("search-type");
      document.body.classList.remove("affiliate");
      document.body.classList.remove("finalexpense");
    } else {
      document.body.classList.remove("affiliate");
      document.body.classList.remove("search-type");
      document.body.classList.remove("finalexpense");
    }

    this.state = {
      mouseCoordinates: {},
      componentSet: "Desktop",
      //modalAlreadyShown: false,
      modalAlreadyShown: true,
      showLeadFormMobile: (isAffiliate || (window && window.location && window.location.search && window.location.search != "" && window.location.search != null && window.location.search != undefined)),
      leadData: {
        firstName: "",
        lastName: "",
        gender: "Male",
        zip:"",
        dobMonth: "",
        dobDay: "",
        dobYear: "",
        phone: "",
        email: "",
        address: ""
      },
      validationErrors: [],
      validationReasons: [],
      type: this.type
    };

  }

  componentDidMount() {    
    this._isMounted = true;
    this.isDebug = (process.env.REACT_APP_STAGE === "qa" || process.env.REACT_APP_STAGE === "dev") && this.props.location.search.includes("debugmode=true");    
    this.showLeadFormForMobile = this.props.location.search.includes("viewtype=form");
    let isAffiliate = this.props.location.search && this.props.location.search.includes("o=");
    let isSearch = this.props.location.search && (this.props.location.search.includes("utm_medium") || this.props.location.search.includes("utm_source"));

    this.initializeBody();
    this.chooseComponentSet();

    window.addEventListener("resize", () => {
      //I hate safari, and you should too
      this.timeoutHandle = setTimeout(()=>{
        (isSearch || isAffiliate) &&
        this.leadFormRef &&
        this.leadFormRef.resizeCite &&
        this.leadFormRef.resizeCite();
        this.componentSetStrategy.run();
        let {state} = this;
        this.setState({
          ...state
        });
      }, 500);      
    });
    let clickId = getClickId();
    this.props.setClickId(clickId);

    const parsedQs = queryString.parse(window.location.search);
    let {leadData} = this.state;
    leadData.firstName = parsedQs.fn || "";
    leadData.lastName = parsedQs.ln || "";
    leadData.gender = parsedQs.g || "Male";
    leadData.zip = parsedQs.z || "";
    leadData.dobMonth = parsedQs.dm || "";
    leadData.dobDay = parsedQs.dd || "";
    leadData.dobYear = parsedQs.dy || "";
    leadData.dob = (parsedQs.dm && parsedQs.dd && parsedQs.dy) ?
      `${parsedQs.dm}/${parsedQs.dd}/${parsedQs.dy}` :
      null;
    leadData.phone = parsedQs.p || "";
    leadData.email = parsedQs.em || "";

    this.setState({leadData, parsedQs});
  }

  componentDidUpdate() {    
    let {
      postLeadDataValidationErrors,
      eventStatuses
    } = this.props;    

    if (eventStatuses["postLeadData"] === EventStatuses.FAILED && postLeadDataValidationErrors) {
      this.props.logEvent("submissionError", JSON.stringify(postLeadDataValidationErrors));
      this.props.acknowledgeEvent("postLeadData");
      this.formatInvalidFields();
    } else if (eventStatuses["postLeadData"] === EventStatuses.FAILED) {
      this.props.logEvent("submissionError - no validation errors");
      this.props.acknowledgeEvent("postLeadData");
      this.setState({
        validationReasons: ["Unable to process the request"]
      });
    }

    if (this.props.eventStatuses["postLeadData"] === EventStatuses.COMPLETED && this.props.leadIdToken) {
      this.props.logEvent("leadAccepted", this.props.leadIdToken);
      this.props.acknowledgeEvent("postLeadData");
      this.props.history.push(`/lead/confirmation${this.props.location.search}`);
    }
  }

  initializeBody = () => {
    document.body.style.opacity = 0;
    document.body.classList.remove("confirmation");    
    document.body.classList.add("landing");
    document.body.classList.add(this.type);
  }
  
  formatInvalidFields = () => {
    let {validationErrors, validationReasons, componentSet} = this.state;
    validationErrors = [];
    validationReasons = [];
    let {postLeadDataValidationErrors} = this.props;
    let anyNew = false;

    let invalidFields = postLeadDataValidationErrors.map(pldve => {
      return {
        field: (pldve.field.charAt(0).toLowerCase() + pldve.field.slice(1)),
        reason: pldve.reason
      };
    });

    invalidFields.forEach(i => {
      if (i.field == "email") {
        anyNew = true;
        validationReasons.push(i.reason + " email address");
        validationErrors.push(i.field);
      } else if (i.field.toLowerCase() != "dateofbirth" && !this.state.validationErrors.includes(i)) {          
        anyNew = true;
        validationReasons.push(i.reason.toLowerCase());
        validationErrors.push(i.field);
      } else if (i.toLowerCase() == "dateofbirth") {
        anyNew = +true;
        validationReasons.push(i.reason.toLowerCase());
        if (componentSet != "Mobile" && componentSet != "iOS13") {
          validationErrors.push("dobDay");
          validationErrors.push("dobMonth");
          validationErrors.push("dobYear");
        } else {
          validationErrors.push("dob");
        }
      }
    });

    if (anyNew && this._isMounted) {
      this.setState({
        validationErrors,
        validationReasons
      });
    }
  }

  pageRendered = () => {
    document.body.style.opacity = 1;
    this.setState({loaded: true}, ()=>{
      this.props.logEvent("pageRendered");
    }); //force re-render
    setTimeout(()=> {      
      this.forceUpdate();
    }, 500);
  }

  runPageLoadApiCalls = () => {
    this.props.trackVisit(this.props.location.search);
    this.props.getSourceForAdditionalOffers(this.props.location.search);
  }

  loadDesktopComponentStrategy = () => {
    import("../Components/Landing/Desktop").then(cmp => this.cmp = cmp.pages);
    import("../Assets/styles/desktop.scss").then(()=>{
      this.pageRendered();
    })
    .then(()=>{
      this.runPageLoadApiCalls();
    });
  }

  loadTabletComponentStrategy = () => {
    import("../Components/Landing/Desktop").then(cmp => this.cmp = cmp.pages);
    import("../Assets/styles/desktop.scss").then(()=>{
        this.pageRendered();
      })
      .then(()=>{
        this.runPageLoadApiCalls();
      });
  }

  loadiOS13ComponentStrategy = () => {
    import("../Components/Landing/iOS13").then(cmp => this.cmp = cmp.pages);
    import("../Assets/styles/iOS13.scss").then(()=>{
      this.pageRendered();
    })
    .then(()=>{
      this.runPageLoadApiCalls();
    });
  }

  chooseComponentSet = () => {
    this.componentSetStrategy.run();
    let componentSet = this.componentSetStrategy.getComponentSet();

    this.componentLoadingStrategies[componentSet] &&
    this.componentLoadingStrategies[componentSet]();

    this._isMounted && this.setState({componentSet});
  }

  submit = () => {
    let showNewScript = process.env.REACT_APP_STAGE !== "prod" && window.location.href.includes("new_script=true");
    if (showNewScript) {
      window.LeadiD.snap();
    }
    if (this._submitButtonClicked) {return;}

    /*this.setState({
      validationErrors: [],
      validationReasons: []
    });*/

    this._submitButtonClicked = true;

    this.props.logEvent("pageSubmitted");

    let {leadData} = this.state;

    let {dob} = leadData;
    if (dob) {
      let splitDob = (dob || "").split("/");
      leadData.dobMonth = splitDob[0];
      leadData.dobDay = splitDob[1];
      leadData.dobYear = splitDob[2];
    }
    
    leadData.phone = leadData.phone.replace("(", "");
    leadData.phone = leadData.phone.replace(")", "");
    leadData.phone = leadData.phone.replace("-", "");
    leadData.phone = leadData.phone.replace(" ", "");

    let leadIdToken = document.getElementById("leadid_token");
    
    let leadDataPayload = {
      ...leadData,
      queryString: this.props.location.search,
      leadIdToken: leadIdToken ? leadIdToken.value : ""
    };

    this.props.postLeadData(leadDataPayload, this.state.componentSet);
  }

  render() {
    let userAgentInfo = this.uaParser.getDevice();
    let deviceType = userAgentInfo.type;
    let deviceVendor = userAgentInfo.vendor;

    let browserInfo = this.uaParser.getBrowser();

    let width = window.outerWidth;
    if (!width || (deviceType == "mobile" && deviceVendor == "Apple")) {
      width = window.innerWidth;
    }
    
    let Cmp = this.cmp;
    let {componentSet, type} = this.state;

    let nonSafariDesktopStyle = {width: "100%", height: "100%", overflowX: "hidden" };
    let safariDesktopStyle = {width: "100%", height: "100%" };

    let isSearch = this.props.location.search && (this.props.location.search.includes("utm_medium") || this.props.location.search.includes("utm_source"));
    //let isFinalExpense = this.props.location.pathname && this.props.location.pathname.includes("final-expense");
    let isFinalExpense = this.state.type == "finalexpense";
    let isAffiliate = this.props.location.search && this.props.location.search.includes("o=");

    return (
      <div
        style={browserInfo.name && browserInfo.name.toLowerCase().includes("safari") ? safariDesktopStyle : nonSafariDesktopStyle}
      >
        <Helmet>
          <meta charSet="utf-8"/>
          <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=yes"/>
          <meta http-equiv="x-ua-compatible" content="IE-Edge"/>
          <meta content="telephone=no" name="format-detection"/>
          <meta name="description" content='GET FREE, Unbiased LIFE Insurance Quotes from Dozens of "A" Rated carriers in just Minutes'/>
          <title>AccuQuote</title>
        </Helmet>
        {
          Cmp &&
          <Cmp.LandingPageHeader
            isFinalExpense={isFinalExpense}
            isSearch={isSearch}
            maxWidth={width}
          />
        }
        {
          Cmp &&
          Cmp.LandingPageHero1 &&
          !this.state.showLeadFormMobile &&
          <Cmp.LandingPageHero1
            isFinalExpense={isFinalExpense}
            isSearch={isSearch}
            queryString={this.props.location.search}
            type={this.type}
            toggleLeadForm={()=>{
              this.setState({showLeadFormMobile: true})
            }}
          />
        }
        {
          Cmp &&
          Cmp.LandingPageMobileFiller &&
          !this.state.showLeadFormMobile &&
          !isSearch &&
          <Cmp.LandingPageMobileFiller
            isSearch={isSearch}
            isFinalExpense={isFinalExpense}
            pageLoaded={this.state.loaded}
          />
        }
        {
          Cmp &&
          Cmp.LandingPageHero2 &&
          (this.state.showLeadFormMobile)&&
          <Cmp.LandingPageHero2
            isSearch={isSearch}
            isFinalExpense={isFinalExpense}
            isAffiliate={isAffiliate}
          />
        }
        {
          Cmp &&
          Cmp.LandingPageLeadForm &&
          (componentSet != "iOS13" || this.state.showLeadFormMobile) &&
          <Cmp.LandingPageLeadForm
            isAffiliate={isAffiliate}
            isSearch={isSearch}
            isFinalExpense={isFinalExpense}
            ref={r => this.leadFormRef = r}
            queryString={this.props.location.search}
            maxWidth={width}
            history={this.props.history}
            leadData={this.state.leadData}
            onFocus={() =>{
              this.props.fieldFocused();
            }}
            onChange={(key, value) => {
              this._submitButtonClicked = false;
              let {leadData} = this.state;
              leadData[key] = value;
              this._isMounted && this.setState({
                leadData
              });
            }}
            onBlur={(key, value) => {
              if (value) {
                let eventName = `${key}Entered`;
                this.props.logEvent(eventName, value);
              }              
            }}
            submit={this.submit}
            validationErrors={this.state.validationErrors}
            validationReasons={uniq(this.state.validationReasons)}
            type={type}
            parsedQs={this.state.parsedQs}
          />
        }
        {
          Cmp &&
          Cmp.LandingPagePromiseSection &&
          <Cmp.LandingPagePromiseSection
            isSearch={isSearch}
            isFinalExpense={isFinalExpense}
          />
        }
        {
          Cmp &&
          Cmp.LandingPagePartnerBrandsSection &&
          <Cmp.LandingPagePartnerBrandsSection
            isAffiliate={isAffiliate}
            isSearch={isSearch}
            isFinalExpense={isFinalExpense}
          />
        }
        {
          Cmp &&
          Cmp.LandingPageStatsSection &&
          <Cmp.LandingPageStatsSection
            isSearch={isSearch}
            isFinalExpense={isFinalExpense}
          />
        }
        {
          Cmp &&
          Cmp.LandingPageTrustedSection &&
          <Cmp.LandingPageTrustedSection/>
        }
        {
          Cmp &&
          Cmp.LandingPageFreeQuoteButton &&
          (isSearch || isFinalExpense) &&
          !this.state.showLeadFormMobile &&
          <Cmp.LandingPageFreeQuoteButton
            toggleLeadForm={()=>{
              this.setState({showLeadFormMobile: true})
            }}
          />
        }
        {
          Cmp &&
          Cmp.LandingPageCertifications &&
          //(componentSet != "iOS13" || this.state.showLeadFormMobile) &&
          <Cmp.LandingPageCertifications/>
        }
        {
          Cmp &&
          Cmp.LandingPageExtras &&
          isSearch &&
          !this.state.showLeadFormMobile &&
          <Cmp.LandingPageExtras/>
        }
        {
          Cmp &&
          Cmp.LandingPageDisclosureSection &&
          (componentSet != "iOS13" || this.state.showLeadFormMobile) &&
          <Cmp.LandingPageDisclosureSection history={this.props.history}/>
        }
        {/*
          //yes, this component appears twice
          Cmp &&
          Cmp.LandingPageMobileFiller &&
          !this.state.showLeadFormMobile &&
          isSearch &&
          <Cmp.LandingPageMobileFiller
            isSearch={isSearch}
            pageLoaded={this.state.loaded}
          />
        */}
        {
          Cmp &&
          Cmp.LandingPageFooter &&          
          <Cmp.LandingPageFooter
            history={this.props.history}
          />  
        }
        <div style={{display: "none"}} id="pixels">
          {
            this.props.clickId != "-1" &&
            this.props.landingPagePixels &&
            this.props.landingPagePixels.map((i, k) => {
              if (this.props.clickId && i.includes("&r=") && !i.includes(`r=${this.props.clickId}`)) {
                i = i.replace("&r=", `&r=${this.props.clickId}`);
              } else if (this.props.clickId && !i.includes("&r=")) {
                i = (i + `&r=${this.props.clickId}`)
              }
              return <iframe key={k} src={i} style={{height: 1, width: 1, frameBorder: 0}}/>
            })
          }
        </div>
        {
          this.props.eventStatuses["postLeadData"] === EventStatuses.PENDING &&
            <div
              className="spinner"
              style={{display: "block"}}
            />
        }
        <LeadIdComp
          formClicked={this.props.formClicked}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {postLeadDataValidationErrors, landingPagePixels, leadIdToken, clickId, formClicked} = state.leadReducer;
  const {eventStatuses} = state.systemReducer;
  return {
    ...state,
    postLeadDataValidationErrors,
    landingPagePixels,
    eventStatuses,
    leadIdToken,
    clickId,
    formClicked
  };
}
export default connect(mapStateToProps, {
  logEvent,
  trackVisit,
  postLeadData,
  acknowledgeEvent,
  getSourceForAdditionalOffers,
  setClickId,
  fieldFocused
})(LandingPage);