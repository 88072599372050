
export const formatDescription = description => {
  let desc = (description || "");

  desc = desc.replace("<ul>", "");
  desc = desc.replace("</ul>", "");

  while(desc.indexOf("</li>") > -1) {
    desc = desc.replace("</li>", "");
  }

  let bulletPoints = desc.split("<li>");

  let returnString = "";

  for(var i = 1; i <= 2; i++) {
    if (!bulletPoints[i]) { break;}
    returnString += `<li>${bulletPoints[i]}</li>`;
  }

  return `<ul>${returnString}</ul>`;
}

export const formatAdditionalHtml = (additionalHtml, idx) => {
  let formattedString = additionalHtml;
  while(formattedString.includes("{position}")) {
    formattedString = formattedString.replace("{position}", (idx + 1))
  }
  return formattedString;
}

export const formatHeadline = headline => {
  let hl = (headline || "");
  let entities = [
    {ent: "&nbsp;", result: " "},
    {ent: "&lt;", result: "<"},
    {ent: "&gt;", result: ">"},
    {ent: "&amp;", result: "&"},
    {ent: "&quot;", result: "\""},
    {ent: "&apos;", result: "'"}
  ];

  entities.forEach(e => {
    while(hl.indexOf(e.ent) > -1) {
      hl = hl.replace(e.ent, e.result);
    }
  });

  return hl;
}

export const getClickId = () => {
  let {search} = window.location;
  if (!search) {return null;}
  search = search.replace("?", "");
  let searchPairs = search.split("&");
  let searchHash = {};
  searchPairs.forEach(sp => {
    let tmp = sp.split("=");
    searchHash[tmp[0].toLowerCase()] = tmp[1];
  });
  return searchHash.clickid;
}